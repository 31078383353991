<template>
  <div class="set-height-100">
    <header-tabs :page-tabs="pageTabs"></header-tabs>
    <div class="header-tab-container current" data-tabid="1">
      <div class="stepper-container">
        <stepper-nav></stepper-nav>
        <div class="stepper-content">
          <div class="stepper-content-inner current" data-tab="1">
            <h2><span v-tooltip="toolTips.elternzeit">Elternzeit</span></h2>
            <table>
              <tr>
                <td>Versicherungsleistung:</td>
                <td>Gemäß der gewählten Höhe</td>
              </tr>
              <tr>
                <td>Max. Auszahlungen je Leistungsfall:</td>
                <td>6</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.deckungen">Max. Zahlungen über alle Deckungen:</span></td>
                <td>36</td>
              </tr>
              <tr>
                <td>Versicherungsfälle pro Angehörigen:</td>
                <td>1</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit">Wartezeit:</span></td>
                <td>9 Monate</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.karenzzeit">Karenzzeit:</span></td>
                <td>-</td>
              </tr>
              <tr>
                <td>Leistungsvoraussetzungen:</td>
                <td>
                  <ul>
                    <li>Kind nach Ablauf der <span v-tooltip.top-center="toolTips.wartezeit">Wartezeit</span> geboren oder adoptiert</li>
                    <li>Eltern beziehen Elterngeld nach dem Bundeselterngeld- und Elternzeitgesetz.</li>
                    <li>Eltern gehen keiner sozialversicherungspflichtigen Beschäftigung von mehr als 30 Stunden pro Woche nach.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>
                  <ul>
                    <li>Kein Leistungsanspruch bei Pflegekindern</li>
                    <li>Kein Leistungsbezug durch Selbstständige, Beamte, Richter, Rentner und Berufs- sowie Zeitsoldaten</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an den Versicherungsnehmer.</td>
              </tr>
            </table>
          </div>
          <div class="stepper-content-inner" data-tab="2">
            <h2><span v-tooltip="toolTips.kurzzeitpflege">Kurzzeitpflege</span></h2>
            <table>
              <tr>
                <td>Versicherungsleistung:</td>
                <td>Gemäß der gewählten Höhe</td>
              </tr>
              <tr>
                <td>Max. Auszahlungen je Leistungsfall:</td>
                <td>6</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.deckungen">Max. Zahlungen über alle Deckungen:</span></td>
                <td>36</td>
              </tr>
              <tr>
                <td>Versicherungsfälle pro Angehörigen:</td>
                <td>1</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit2">Wartezeit:</span></td>
                <td>9 Monate</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.karenzzeit">Karenzzeit:</span></td>
                <td>-</td>
              </tr>
              <tr>
                <td>Leistungsvoraussetzungen:</td>
                <td>
                  <ul>
                    <li>Versicherungsleistung hängt von der Inanspruchnahme einer staatlich geförderten Aufstockung des Arbeitsentgelts während der Pflegezeit ab (nur für <span v-tooltip="toolTips.verwandte">nahe Angehörige</span>).</li>
                    <li>Versicherte Person geht einer sozialversicherungspflichtigen Beschäftigung von mehr als 15 Stunden pro Woche nach.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>
                  Kein Leistungsbezug durch Selbstständige, Beamte, Richter, Rentner und Berufs- sowie Zeitsoldaten
                </td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an den Versicherungsnehmer.</td>
              </tr>
            </table>
          </div>
          <div class="stepper-content-inner" data-tab="3">
            <h2><span v-tooltip="toolTips.kurzarbeit">Konjunkturelle Kurzarbeit</span></h2>
            <table>
              <tr>
                <td>Versicherungsleistung:</td>
                <td>Gemäß der gewählten Höhe</td>
              </tr>
              <tr>
                <td>Max. Auszahlungen je Leistungsfall:</td>
                <td>12</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.deckungen">Max. Zahlungen über alle Deckungen:</span></td>
                <td>36</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit2">Wartezeit:</span></td>
                <td>3 Monate</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.karenzzeit">Karenzzeit:</span></td>
                <td>-</td>
              </tr>
              <tr>
                <td>Leistungsvoraussetzungen:</td>
                <td>
                  <ul>
                    <li>Anspruch auf Kurzarbeitergeld nach § 95 SGB III bei betriebsbedingter konjunktureller Kurzarbeit</li>
                    <li>Versicherte Person geht einer sozialversicherungspflichtigen Beschäftigung von mehr als 15 Stunden pro Woche nach.</li>
                    <li>Die Kurzarbeit ist erstmalig nach Ablauf der Wartezeit eingetreten.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>
                  <ul>
                    <li>Kein Leistungsbezug durch Selbstständige, Beamte, Richter, Rentner und Berufs- sowie Zeitsoldaten</li>
                    <li>Kein Leistungsbezug bei freiwilliger Kurzarbeit, Altersteilzeit, Streik, saisonaler Kurzarbeit</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an den Versicherungsnehmer.</td>
              </tr>
            </table>
          </div>
          <div class="stepper-content-inner" data-tab="4">
            <h2><span v-tooltip="toolTips.arbeitslosigkeit">Arbeitslosigkeit</span></h2>
            <table>
              <tr>
                <td>Versicherungsleistung:</td>
                <td>Gemäß der gewählten Höhe</td>
              </tr>
              <tr>
                <td>Max. Auszahlungen je Leistungsfall:</td>
                <td>12</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.deckungen">Max. Zahlungen über alle Deckungen:</span></td>
                <td>36</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit2">Wartezeit:</span></td>
                <td>3 Monate</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.karenzzeit">Karenzzeit:</span></td>
                <td>2 Monate</td>
              </tr>
              <tr>
                <td>Leistungsvoraussetzungen:</td>
                <td>
                  <ul>
                    <li>Mindestens 6 Monate beim Arbeitgeber</li>
                    <li>Bezug von Arbeitslosengeld wegen Arbeitslosigkeit</li>
                    <li>Arbeitslosigkeit muss unverschuldet erfolgen.</li>
                    <li>Versicherte Person geht einer sozialversicherungspflichtigen Beschäftigung von mehr als 15 Stunden pro Woche nach.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>
                  <ul>
                    <li>Kein Leistungsbezug durch Selbstständige, Beamte, Richter, Rentner und Berufs- sowie Zeitsoldaten</li>
                    <li>Kein Leistungsbezug bei Eigenkündigung sowie nach Auslauf eines befristeten Vertrages</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an den Versicherungsnehmer.</td>
              </tr>
            </table>
          </div>
          <div class="stepper-content-inner" data-tab="5" data-stoerer="Keine Gesundheitsfragen">
            <h2><span v-tooltip="toolTips.schwereKrankheiten">Schwere Krankheiten</span></h2>
            <table>
              <tr>
                <td>Versicherungsleistung:</td>
                <td>Gemäß der gewählten Höhe</td>
              </tr>
              <tr>
                <td>Max. Auszahlungen je Leistungsfall:</td>
                <td>12</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.deckungen">Max. Zahlungen über alle Deckungen:</span></td>
                <td>36</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit2">Wartezeit:</span></td>
                <td>3 Monate</td>
              </tr>
              <tr>
                <td>Leistungsvoraussetzungen:</td>
                <td>
                  <ul>
                    <li>Gilt nur für Beamte, Selbstständige oder Rentner.</li>
                    <li>Die ärztliche Diagnose bzw. Bestätigung muss endgültig und eindeutig sein.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>Kein Leistungsbezug bei Erkrankung in Folge von Alkoholismus und anderen Suchterkrankungen</td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an den Versicherungsnehmer.</td>
              </tr>
            </table>
          </div>
          <div class="stepper-content-inner" data-tab="6">
            <h2><span v-tooltip="toolTips.arbeitsunfaehigkeit">Arbeitsunfähigkeit (Inklusive psychischer Erkrankung)</span></h2>
            <table>
              <tr>
                <td>Versicherungsleistung:</td>
                <td>Gemäß der gewählten Höhe</td>
              </tr>
              <tr>
                <td>Max. Auszahlungen je Leistungsfall:</td>
                <td>12</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.deckungen">Max. Zahlungen über alle Deckungen:</span></td>
                <td>36</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit2">Wartezeit:</span></td>
                <td>3 Monate (entfällt bei Unfall)</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.karenzzeit">Karenzzeit:</span></td>
                <td>42 Tage (Nach jeder Unterbrechung der Arbeitsunfähigkeit beginnt die Karenzzeit erneut. Dies gilt auch bei gleicher Krankheitsart wie bereits zuvor.)</td>
              </tr>
              <tr>
                <td>Leistungsvoraussetzungen:</td>
                <td>
                  <ul>
                    <li>Nachweis einer ärztlichen Erst- oder Folgebescheinigung oder Arbeitsunfähigkeitsbescheinigung inklusive Diagnose (Ausfertigung für die Krankenkasse oder versicherte Person)</li>
                    <li>Bei psychischen Erkrankungen muss nach den ersten drei Krankheitsmonaten ein Nachweis eines niedergelassenen Facharztes für Psychiatrie vorgelegt werden.</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>
                  <ul>
                    <li>Kein Leistungsbezug bei Alkoholismus und anderen Suchterkrankungen</li>
                    <li>Kein Leistungsbezug nach Folgen einer medizinisch nicht notwendigen Behandlung (z. B. Schönheitsoperationen)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an den Versicherungsnehmer.</td>
              </tr>
            </table>
          </div>
          <div class="stepper-content-inner" data-tab="7">
            <h2><span v-tooltip="toolTips.unfalltod">Unfalltod</span></h2>
            <table>
              <tr>
                <td>Einmalige Versicherungssumme:</td>
                <td>50-Faches der ALO-Leistung (max. 50.000 €)</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.wartezeit2">Wartezeit:</span></td>
                <td>-</td>
              </tr>
              <tr>
                <td><span v-tooltip="toolTips.karenzzeit">Karenzzeit:</span></td>
                <td>-</td>
              </tr>
              <tr>
                <td>Ausschlüsse:</td>
                <td>
                  <ul>
                    <li>Kein Leistungsbezug bei risikotragenden Sportarten (lizenzpflichtiger Motor- und Luftsport)</li>
                    <li>Kein Leistungsbezug bei professionell ausgeübtem Sport</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Altersgrenzen:</td>
                <td>
                  <ul>
                    <li>Eintrittsalter: 18 Jahre</li>
                    <li>Höchstalter: 64 Jahre</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Leistung:</td>
                <td>Die Zahlung erfolgt direkt an die Hinterbliebenen.</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="header-tab-container padded" data-tabid="2">
      <div class="row">
        <div class="col-2">
          <h2 class="pt-xs">Tarifübersicht</h2>
        </div>
        <div class="col-10">
          <tariff-options></tariff-options>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTabs from "../../components/HeaderTabs";
import TariffOptions from "../../components/TariffOptions";
import StepperNav from "../../components/StepperNav";
export default {
  name: "Produkte",
  components: {
    StepperNav,
    HeaderTabs,
    TariffOptions
  },
  data() {
    return {
      toolTips: {
        elternzeit:
            '<h3>Elternzeit</h3>' +
            '<ul>' +
            '<li>Das Elterngeld soll den Einkommenseinbruch nach der Geburt eines Kindes reduzieren und wird von den staatlichen Elterngeldstellen ausgezahlt.</li>' +
            '<li>Elternzeit kann jedes Elternteil in Anspruch nehmen, das in einem Arbeitsverhältnis steht (Vollzeit, Teilzeit, Ausbildung, geringfügige und/oder befristete Beschäftigung).</li>' +
            '<li>Das Basiselterngeld beträgt i. d. R. zwischen 65 % und 67 % des Nettoeinkommens, aber mind. 300 € bzw. max. 1.800 € monatlich.</li>' +
            '</ul>',
        deckungen:
            '<h3>Max. Zahlungen über alle Deckungen</h3>' +
            '<p>Der Kunde erhält während der gesamten Vertragslaufzeit max. 36 Zahlungen – über alle Deckungen und Schadenfälle hinweg. Die Leistung für den Unfalltod ist hiervon ausgenommen.</p>',
        wartezeit:
            '<h3>Wartezeit</h3>' +
            '<p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz und damit kein Leistungsanspruch besteht. Erst wenn nach der Wartezeit ein Kind geboren oder adoptiert wird, hat der Kunde einen Leistungsanspruch.</p>',
        wartezeit2:
            '<h3>Wartezeit</h3>' +
            '<p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz und damit kein Leistungsanspruch besteht. Erst nach der Wartezeit hat der Kunde einen Leistungsanspruch.</p>',
        karenzzeit:
            '<h3>Karenzzeit</h3>' +
            '<p>Die Karenzzeit ist die leistungsfreie Zeit nach Eintritt des Versicherungsfalles, für die kein Leistungsanspruch geltend gemacht werden kann.</p>',
        kurzzeitpflege:
            '<h3>Kurzzeitpflege</h3>' +
            '<ul>' +
            '<li>Familienpflegezeit / Kurzzeitpflege bezeichnet die teilweise Freistellung für die Pflege eines pflegebedürftigen nahen Angehörigen in häuslicher Umgebung über einen Zeitraum von bis zu 24 Monaten bei einer wöchentlichen Mindestarbeitszeit von 15 Stunden.</li>' +
            '<li>Beschäftigte in Unternehmen mit 25 oder mehr Angestellten haben einen Anspruch auf Familienpflegezeit.</li>' +
            '</ul>',
        verwandte:
            '<h3>Nahe Angehörige</h3>' +
            '<ul>' +
            '<li>Als nahe Angehörige gelten: Großeltern und Eltern, Schwiegereltern, Ehegatten, Lebenspartner oder Partner einer eheähnlichen Gemeinschaft, Stiefeltern, Lebenspartner der Geschwister, Geschwister der Lebenspartner sowie für Partner in lebenspartnerschaftsähnlichen Gemeinschaften, Geschwister, Kinder, Adoptivkinder sowie Kinder oder Adoptivkinder des Ehegatten oder Lebenspartners, Schwieger- und Enkelkinder.</li>' +
            '<li>Pflegekinder gelten nicht als nahe Angehörige.</li>' +
            '</ul>',
        kurzarbeit:
            '<h3>Konjunkturelle Kurzarbeit</h3>' +
            '<ul>' +
            '<li>Konjunkturelle Kurzarbeit ist für ein Unternehmen das Mittel der Wahl, wenn Mitarbeiter aufgrund der wirtschaftlichen Situation unterbeschäftigt sind, aber nicht entlassen werden sollen.</li>' +
            '<li>Sie kann bei Auftragsausfällen durch entsprechende Vereinbarungen zur Reduzierung der Arbeitszeit im Betrieb sehr kurzfristig eingeführt werden.</li>' +
            '</ul>',
        arbeitslosigkeit:
            '<h3>Arbeitslosigkeit</h3>' +
            '<p>Arbeitslose sind Personen, die […] nicht in einem Beschäftigungsverhältnis stehen, eine versicherungspflichtige Beschäftigung suchen und dabei den Vermittlungsbemühungen der Agentur für Arbeit zur Verfügung stehen und sich bei der Agentur für Arbeit arbeitslos gemeldet haben (§ 16 Absatz 1 SGB III).</p>',
        schwereKrankheiten:
            '<h3>Schwere Krankheiten</h3>' +
            '<ul>' +
            '<li>Der Versicherer ordnet folgende Erkrankungen als schwere Krankheiten ein: invasiver Krebs, Herzinfarkt, Schlaganfall, Nierenversagen, Hauptorgantransplantation, Bypassoperation der Koronararterien.</li>' +
            '<li>Mehr Infos in den AVBs unter „Besondere Bedingungen für den Leistungsbaustein Santander Protect bei schweren Krankheiten“</li>' +
            '</ul>',
        grobeFahrlaessigkeit:
            '<h3>Grobe Fahrlässigkeit</h3>' +
            '<p>Es wird grob fahrlässig gehandelt, wenn ein Schaden durch einfache und naheliegende Verhaltensweisen hätte verhindert werden können und diese außer Acht gelassen wurden.</p>',
        arbeitsunfaehigkeit:
            '<h3>Arbeitsunfähigkeit</h3>' +
            '<p>Ein Arbeitnehmer ist arbeitsunfähig, wenn er aufgrund einer Krankheit seine zuletzt ausgeübte Tätigkeit nicht mehr ausführen kann oder die Ausführung der jeweiligen Tätigkeit mit der Gefahr der Verschlimmerung der Krankheit einhergeht.</p>',
        unfalltod:
            '<h3>Unfalltod</h3>' +
            '<p>Ein Unfall liegt vor, wenn die versicherte Person durch ein plötzlich von außen auf den Körper wirkendes Ereignis (Unfallereignis) unfreiwillig eine Gesundheitsschädigung mit Todesfolge erleidet.</p>',

      },
      pageTabs: {
        1: {
          'name': 'Protect'
        },
        2: {
          'name': 'Tarifvarianten'
        }
      }
    }
  }
};
</script>
