<template>
  <div class="stepper-nav">
    <ul class="stepper-nav-list" data-name="Premium">
      <li data-tab="1" :class="{'current': noSelection !== true}">Elternzeit</li>
      <li data-tab="2">Kurzzeitpflege</li>
    </ul>
    <ul class="stepper-nav-list" data-name="Standard">
      <li data-tab="3">Kurzarbeit</li>
      <li data-tab="4">Arbeitslosigkeit</li>
      <li data-tab="5">Schwere Krankheiten</li>
    </ul>
    <ul class="stepper-nav-list" data-name="Basis">
      <li data-tab="6">Arbeitsunfähigkeit<small>(Inklusive psychischer Erkrankung)</small></li>
      <li data-tab="7">Unfalltod</li>
    </ul>
  </div>
</template>
<script>
export default {
  name: "StepperNav",
  props: ['noSelection']
};
</script>
