<template>
  <div :class="{ loader: true, fadeout: !isLoading }">
    <div class="startscreen-inner">
      <div class="startscreen-headline">
        <strong>Santander</strong> Protect
      </div>
      <a href="#" class="btn mt-xs">Alle Infos für deine Beratung</a>
    </div>

    <div class="startscreen-footer">
      <div class="startscreen-footer-inner">
        <img src="@/assets/img/santander-logo.svg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StartScreen",
  props: ["isLoading"]
};
</script>
