<template>
  <div id="app">
    <StartScreen v-if="isLoading" />
    <transition name="slide-fade" mode="out-in">
      <div class="set-height-100">
        <Header :is-not-start="isNotStart()" />
        <div id="main" :class="{'route-wrapper': isNotStart(), 'grid-wrapper': !isNotStart()}">
          <router-view />
        </div>
        <div v-if="isNotStart()" class="print-button-wrapper">
          <button type="button" @click="printPage">
            <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" width="40px">
              <path
                  d="M384 368h24a40.12 40.12 0 0040-40V168a40.12 40.12 0 00-40-40H104a40.12 40.12 0 00-40 40v160a40.12 40.12 0 0040 40h24"
                  fill="none" stroke="#9bc3d3" stroke-linejoin="round" stroke-width="32"/>
              <rect x="128" y="240" width="256" height="208" rx="24.32" ry="24.32" fill="none" stroke="#9bc3d3"
                    stroke-linejoin="round" stroke-width="32"/>
              <path d="M384 128v-24a40.12 40.12 0 00-40-40H168a40.12 40.12 0 00-40 40v24" fill="none" stroke="#9bc3d3"
                    stroke-linejoin="round" stroke-width="32"/>
              <circle cx="392" cy="184" r="24"/>
            </svg>
          </button>
        </div>
        <FooterNav/>
      </div>
    </transition>
  </div>
</template>

<script>
import Header from './components/Header';
import StartScreen from './components/StartScreen';
import FooterNav from './components/FooterNav';

export default {
  name: 'App',
  title: 'Santander Protect',
  data: () => ({
    el: '#app',
    isLoading: true,
  }),
  mounted() {
    setTimeout(() => {
      this.isLoading = false
    }, 3000);
  },
  components: {
    Header,
    StartScreen,
    FooterNav
  },
  methods: {
    isNotStart() {
      return this.$router.history.current["path"] !== "/";
    },
    printPage() {
      window.electronAPI.previewComponent(null, (response) => {
        console.log('Main: ', response);
      });
    }
  },
}
</script>
