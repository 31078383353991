<template>
  <div>
      <img src="@/assets/img/balken3_04.png" />
  </div>
</template>
<script>
export default {
  name: "TariffOptions"
};
</script>
