<template>
  <div class="qr-container">
    <p class="mb-none font-size-md">Einen Leistungsfall selber melden:</p>
    <h2 class="pt-none text-transform-none">eClaims</h2>
    <div class="grid-split -split-3" :style="{ 'column-gap': this.gap + 'rem' }">
      <div class="qr-wrapper">
        <img src="@/assets/img/qrcode_www.eclaims.cnpsantander.de.png" width="225" />
        <span class="qr-subline">
          <a target="_blank" href="https://www.cnpsantander.de">www.cnpsantander.de</a>
        </span>
      </div>
      <div class="qr-wrapper">
        <img src="@/assets/img/qrcode_play.google.com.png" width="225" />
        <span class="qr-subline">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="32.16 68.51 203.69 228.16">
                <path d="M101.89 207.1c7.86 0 14.24 6.37 14.24 14.23v61.1a14.24 14.24 0 0 1-28.48 0v-61.1c0-7.86 6.37-14.24 14.24-14.24z" fill="#000"/>
                <path d="M69.37 133.65c-.04.54-.08 1.08-.08 1.63v92.56c0 9.95 7.87 17.97 17.66 17.97h94.12c9.78 0 17.66-8.02 17.66-17.97v-92.56c0-.55-.02-1.1-.06-1.63H69.36z" fill="#000"/>
                <path d="M166.13 207.1c7.87 0 14.24 6.37 14.24 14.23v61.1a14.24 14.24 0 0 1-28.48 0v-61.1c0-7.86 6.38-14.24 14.24-14.24zM46.41 141.87c7.86 0 14.24 6.38 14.24 14.24v61.1a14.24 14.24 0 0 1-28.49 0v-61.1c0-7.86 6.38-14.24 14.25-14.24zm175.2 0c7.87 0 14.24 6.38 14.24 14.24v61.1a14.24 14.24 0 0 1-28.48 0v-61.1c0-7.86 6.38-14.24 14.24-14.24zM69.8 127.57c.4-28.43 25.21-51.74 57.06-54.82h14.31c31.86 3.08 56.67 26.39 57.07 54.81H69.79z" fill="#000"/>
                <path d="m74.74 70 15.03 26.03M193.26 70l-15.02 26.02" fill="none" stroke="#000" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M114.88 102.09a7.28 7.28 0 0 1-7.35 7.21 7.29 7.29 0 0 1-7.39-7.17v-.04a7.28 7.28 0 0 1 7.35-7.22 7.28 7.28 0 0 1 7.39 7.18v.04zm55 0a7.28 7.28 0 0 1-7.35 7.21 7.29 7.29 0 0 1-7.4-7.17v-.04a7.28 7.28 0 0 1 7.36-7.22 7.28 7.28 0 0 1 7.38 7.18v.04z" fill="#fff"/>
              </svg>
              Android
            </span>
      </div>
      <div class="qr-wrapper">
        <img src="@/assets/img/qrcode_apps.apple.com.png" width="225" />
        <span class="qr-subline">
              <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 814 1000">
                <path d="M788.1 340.9c-5.8 4.5-108.2 62.2-108.2 190.5 0 148.4 130.3 200.9 134.2 202.2-.6 3.2-20.7 71.9-68.7 141.9-42.8 61.6-87.5 123.1-155.5 123.1s-85.5-39.5-164-39.5c-76.5 0-103.7 40.8-165.9 40.8s-105.6-57-155.5-127C46.7 790.7 0 663 0 541.8c0-194.4 126.4-297.5 250.8-297.5 66.1 0 121.2 43.4 162.7 43.4 39.5 0 101.1-46 176.3-46 28.5 0 130.9 2.6 198.3 99.2zm-234-181.5c31.1-36.9 53.1-88.1 53.1-139.3 0-7.1-.6-14.3-1.9-20.1-50.6 1.9-110.8 33.7-147.1 75.8-28.5 32.4-55.1 83.6-55.1 135.5 0 7.8 1.3 15.6 1.9 18.1 3.2.6 8.4 1.3 13.6 1.3 45.4 0 102.5-30.4 135.5-71.3z"/>
              </svg>
              iPhone
            </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "eClaims",
  props: {
    gap: String,
  },
  data() {
    return {
      isElectronBuild: true,
    };
  }
};
</script>
