<template>
  <li class="accordion__item">
    <div
        class="accordion__trigger"
        :class="{'accordion__trigger_active': visible}"
        @click="open"
    >
      <slot name="accordion-trigger"></slot>
    </div>

    <transition
        name="accordion"
        @enter="start"
        @after-enter="end"
        @before-leave="start"
        @after-leave="end"
    >
      <div class="accordion__content" :class="{'visible': visible}">
        <slot name="accordion-content"></slot>
      </div>
    </transition>
  </li>
</template>


<script>
export default {
  inject: [
      'Accordion'
  ],
  data() {
    return {
      index: null
    };
  },
  computed: {
    visible() {
      return this.index === this.Accordion.active;
    }
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.maxHeight = (el.scrollHeight + 10) + 'px';
    },
    end(el) {
      el.style.maxHeight = '';
    }
  },
  created() {
    this.index = this.Accordion.count++;
  }
};
</script>

