<template>
  <div class="set-height-100">
    <div class="grid-split -split-3 set-height-100 bg-white">
      <div class="grid-split-item">
        <h2>Grundlagen</h2>
        <p class="font-size-md">Alles Wichtige zu unserem Beratungsanspruch und unseren Versicherungsprodukten</p>
        <p class="font-size-md"><strong>Für dich zusammengefasst</strong></p>
        <img src="@/assets/img/grundlagen.jpg" />
      </div>
      <div class="grid-split-item">
        <h2>Beratung</h2>
        <p class="font-size-md">Nützliche Übersichten und Tools, die du zur Optimierung deines Beratungsgesprächs nutzen kannst</p>
        <p class="font-size-md"><strong>Für dich und deine Kunden</strong></p>
        <img src="@/assets/img/beratungstools.jpg" />
      </div>
      <div class="grid-split-item">
        <h2>Hintergrundwissen</h2>
        <p class="font-size-md">Alle wichtigen Informationen zu Versicherungsbedingungen, Schadenmeldungen und Kontaktadressen</p>
        <p class="font-size-md"><strong>Für dich zum Nachschlagen</strong></p>
        <img src="@/assets/img/hintergrundwissen.jpg" />
      </div>
    </div>
    <div class="version-number" v-if="!isNotStart && version">
      <small>{{version}}</small>
    </div>
  </div>
</template>

<script>
export default {
  name: "Overview",
   data: () => ({
    version: '06/2022',
  }),
};
</script>
