<template>
  <header>
    <div class="container">
      <div class="row">
        <div class="col-12 dis-flex flex-direction-row-reverse flex-wrap-wrap flex-align-items-center">
          <router-link :to="{ name: 'Overview' }" class="logo">
            <img src="@/assets/img/santander-logo.svg" />
          </router-link>
          <div class="box-wrapper" v-if="!isNotStart && !infoHidden">
            <div class="info-box">Die Anwendung bitte auf den Monitor ziehen,<br> um Darstellungsfehler zu vermeiden.</div>
            <div class="info-sign"></div>
          </div>
          <div class="placeholder" v-if="!isNotStart && infoHidden">
          </div>
          <div class="header-headline" v-if="!isNotStart">
            <h1 class="c-primary1">Alles auf einen Blick</h1>
          </div>
          <div id="pageTabsNavHolder">
          </div>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  props: {
    'isNotStart': Boolean,
  },
  data() {
    return {
      infoHidden: false,
    }
  },
  mounted () {
    setTimeout(() => {
      this.infoHidden = true
    }, 13000);
  },
  created () {
    setTimeout(() => {
      this.infoHidden = true
    }, 13000);
  }
};
</script>
