<template>
  <div class="avb-wrapper">
    <div class="stepper-container">
      <div class="stepper-nav -small" style="background: #9bc3d3 !important;">
        <ul class="stepper-nav-list -default" style="padding: 0.5rem .5rem;" data-name="">
          <li data-tab="0" class="current"><a href="#step-0">Allgemeine Vertragsinformationen</a></li>
          <li data-tab="1"><a href="#step-1">Allgemeine Bedingungen für Santander Protect</a></li>
        </ul>
        <ul class="stepper-nav-list -default" style="padding: 0.5rem .5rem;" data-name="">
          <li data-tab="3"><a href="#step-3">Arbeitsunfähigkeit</a></li>
          <li data-tab="4"><a href="#step-4">Arbeitslosigkeit</a></li>
          <li data-tab="5"><a href="#step-5">Unfalltod</a></li>
          <li data-tab="6"><a href="#step-6">Konjunkturelle Kurzarbeit</a></li>
          <li data-tab="7"><a href="#step-7">Elternzeit oder Pflege eines nahen Verwandten</a></li>
        </ul>
        <ul class="stepper-nav-list -default" style="padding: 0.5rem .5rem;" data-name="">
          <li data-tab="8"><a href="#step-8">Schwere Krankheiten</a></li>
          <li data-tab="10"><a href="#step-10">Wichtige Hinweise zur Datenverarbeitung</a></li>
        </ul>
      </div>
      <div class="stepper-content current"  style="height: calc(100vh - 19rem); margin-top: 1.5rem; overflow-y: scroll;">
        <div>
          <h1 class="pb-md">Die Versicherungsbedingungen – für dich im Überblick</h1>

          <a class="anchor-link" name="step-0" id="step-0"></a>
          <h2>Allgemeine Vertragsinformationen entsprechend § 1 der VVG-Informationspflichtenverordnung</h2>

          <p>
            <strong>§1. Rechtliche Verhältnisse</strong><br>
            Der Versicherungsschutz Santander Protect kommt zwischen Ihnen, dem
            Versicherungsnehmer, und uns, der CNP Santander Insurance Europe DAC (siehe Nr. 2), zustande. Unsere
            Hauptgeschäftstätigkeit ist das Versicherungsgeschäft. Ein Garantiefond oder andere
            Entschädigungsregelungen bestehen nicht.
          </p>
          <p>Für das Versicherungsverhältnis gelten neben dem
            Versicherungsantrag diese Vertragsinformationen einschließlich der unten aufgeführten Allgemeinen und
            Besonderen Versicherungsbedingungen, des Versicherungsscheins, dem Informationsblatt zu Versicherungsprodukten,
            den Datenschutzhinweisen. Die wesentlichen Merkmale der Versicherungsleistung, insbesondere Art, Umfang und Fälligkeit der Leistungen sind dem Informationsblatt zu Versicherungsprodukten und den Versicherungsbedingungen zu entnehmen. Es gilt das Recht der Bundesrepublik Deutschland. Vertragssprache ist deutsch.
          </p>
          <p>
            <strong>§2. Versicherer für die Santander Protect Versicherung</strong><br>
            Wir, der Versicherer für die Santander Protect (DP), sind die CNP Santander Insurance Europe DAC, 2nd Floor, Three Park Place, Hatch Street Upper, Dublin 2, Ireland, Telefon 0800-5888 523 (kostenlos). Die Handelsregisternummer lautet: Nr.488062, eingetragen bei der Companies Registration Office. Mitglieder des Boards of Directors: Joaqu&iacute;n Capdevila Coromina (Spanish), Michael Netzel (German), Francois Tritz, Emmanuelle Roux, Thomas Chardonnel, Olivier Pekmezian, Guillaume Kuch (All French), Ciaran McGettrick, Ruth Patterson (Irish).
          </p>
          <p>
            <strong>§3. Versicherungsverhältnis</strong><br>
            Das Versicherungsverhältnis kommt durch Unterzeichnung des Versicherungsantrages durch Sie, den Versicherungsnehmer, und unserer Annahmeerklärung durch Überlassung des Versicherungsscheins zustande, sofern Sie Ihre Vertragserklärung (= Unterzeichnung des Versicherungsantrages) nicht nach deren Abgabe wieder wirksam widerrufen (§ 1 der Allgemeine Versicherungsbedingungen der Santander Protect Versicherung).
          </p>
          <p><strong>§4. Beschwerden </strong><br>
            Unser Kundenservice ist für Sie da (Tel.: 0800 - 5888 523 kostenlos), wenn Sie Anregungen oder Beschwerden haben. Sollten wir das Problem telefonisch nicht lösen können, schreiben Sie uns bitte eine E-Mail an kundenservice@ger.cnpsantander.com oder schreiben Sie uns per Post. Sollten weitere Optionen für eine Beschwerde erforderlich werden, können Sie sich an unserem Vorstand wenden. Konnten wir die Angelegenheit nicht zu Ihrer Zufriedenheit klären, können Sie sich auch an die Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin), Graurheindorfer Str. 108 in 53117 Bonn oder Marie-Curie-Str. 24-28 60439 Frankfurt oder an The Financial Services Ombudsman, Lincoln House, Lincoln Place, Dublin 2 oder die Central Bank of Ireland, Spencer Dock, Dublin 1, wenden. Die Möglichkeit, den Rechtsweg zu beschreiten, bleibt hiervon unberührt.
          </p>
          <p>
            <strong>§5. Gerichtsstand</strong><br> Sind Sie als Versicherungsnehmer eine natürliche Person, ist auch das Gericht
            örtlich zuständig,
            in dessen
            Bezirk Sie als Versicherungsnehmer zur Zeit der Klageerhebung Ihren Wohnsitz oder, falls nicht vorhanden, Ihren gewöhnlichen Aufenthaltsort haben. Sind Sie als Versicherungsnehmer eine natürliche Person, müssen Klagen gegen Sie als Versicherungsnehmer bei dem Gericht erhoben werden, das für Ihren Wohnsitz oder, falls nicht vorhanden, den Ort des gewöhnlichen Aufenthaltes zuständig ist. Sind Sie als der Versicherungsnehmer eine juristische Person, bestimmt sich das zuständige Gericht für Ihre Klagen oder Klagen gegen Sie nach Ihrem Sitz oder Ihrer Niederlassung. Verlegen Sie als Versicherungsnehmer Ihren Wohnsitz in einen Staat außerhalb der Europäischen Union, Islands, Norwegens oder der Schweiz, ist der Gerichtsstand Köln.
          </p>
          <p>
            <strong>§6. Mitteilungen, die das Versicherungsverhältnis betreffen</strong><br>Mitteilungen, die das Versicherungsverhältnis betreffen, bedürfen der Textform, sofern nicht gesetzlich eine andere Form vorgeschrieben ist; sie werden mit Zugang wirksam.</p>
          <a class="anchor-link" name="step-1" id="step-1"></a>

          <h2>Allgemeine Versicherungsbedingungen der Santander Protect Versicherung<br>(AVB- Santander Protect) <small style="font-size: 1rem">(202110v4)</small></h2>

          <p>
            <strong>§1. Widerrufsbelehrung</strong>
          </p>
          <div class="font-weight-bold bg-gray-lightest pa-md mb-md">

            <p style="text-align: center">
              <u><strong>Widerrufsbelehrung<br>Abschnitt 1<br>Widerrufsrecht, Widerrufsfolgen und besondere Hinweise<br>
                Widerrufsrecht</strong></u>
            </p>
            <p>
              <strong>Sie können Ihre Vertragserklärung innerhalb einer Frist von 30 Tagen ohne Angabe von Gründen in Textform (z.B. Brief, Fax, E-Mail) widerrufen.</strong>
            </p>
            <p>
              <strong>Die Widerrufsfrist beginnt, nachdem Ihnen</strong>
            </p>

            <ul>
              <li>der Versicherungsschein,</li>
              <li>die Vertragsbestimmungen, <span class="font-weight-regular">einschließlich der für das Vertragsverhältnis geltenden Allgemeinen Versicherungsbedingungen, diese wiederum einschließlich der Tarifbestimmungen.</span></li>
              <li>dieser Belehrung</li>
              <li>das Informationsblatt zu Versicherungsprodukten</li>
              <li>und die weiteren in Abschnitt 2 aufgeführten Informationen</li>
            </ul>
            <p>jeweils in Textform zugegangen sind.</p>
            <p>Zur Wahrung der Widerrufsfrist genügt die rechtzeitige Absendung des Widerrufs. Der Widerruf ist zu richten an: </p>
            <p>CNP Santander Insurance Europe DAC, 2nd Floor, Three Park Place, Hatch Street Upper, Dublin, Irland</p>

            <p class="text-center"><u>Widerrufsfolgen</u></p>


            <p>Im Falle eines wirksamen Widerrufs endet der Versicherungsschutz und der Versicherer hat Ihnen den auf die Zeit nach Zugang des Widerrufs entfallenden Teil der Prämie zu erstatten, wenn Sie zugestimmt haben, dass der Versicherungsschutz vor dem Ende der Widerrufsfrist beginnt. Den Teil der Prämie, der auf die Zeit bis zum Zugang des Widerrufs entfällt, darf der Versicherer in diesem Fall einbehalten; der sich wie folgt errechnet: Anzahl der Tage, an denen Versicherungsschutz bestanden hat, d. h. bis zum Zugang des Widerrufs, geteilt durch die ursprüngliche (vertraglich vereinbarte gesamte) Versicherungsdauer in Tagen, multipliziert mit dem Einmalbeitrag. Der Versicherer hat zurückzuzahlende Beträge unverzüglich, spätestens 30 Tage nach Zugang des Widerrufs, zu erstatten.</p>
            <p>Beginnt der Versicherungsschutz nicht vor dem Ende der Widerrufsfrist, so hat der wirksame Widerruf zur Folge, dass empfangene Leistungen zurückgewähren und gezogene Nutzungen (z.B. Zinsen) herauszugeben sind.</p>
            <p>Haben Sie Ihr Widerrufsrecht hinsichtlich des Versicherungsvertrages wirksam ausgeübt, so sind Sie auch an einem mit dem Versicherungsvertrag zusammenhängenden Vertrag nicht mehr gebunden. Ein zusammenhängender Vertrag liegt vor, wenn er einen Bezug zu dem widerrufenen Vertrag aufweist und eine Dienstleistung des Versicherers oder einer Dritten auf der Grundlage einer Vereinbarung zwischen dem Dritten und dem Versicherer betrifft. Eine Vertragsstrafe darf weder vereinbart noch verlangt werden.</p>
            <p class="text-center"><u>Besondere Hinweise</u></p>
            <p>Ihr Widerrufsrecht erlischt, wenn der Vertrag auf Ihren ausdrücklichen Wunsch sowohl von Ihnen als auch vom Versicherer vollständig erfüllt ist, bevor Sie Ihr Widerrufsrecht ausgeübt haben.</p>
            <p class="text-center"><u>Abschnitt 2</u></p>
            <p class="text-center"><u>Auflistung der für den Fristbeginn erforderlichen weiteren Informationen</u></p>

            <p class="font-weight-regular">Hinsichtlich der in Abschnitt 1 Satz 2 genannten weiteren Informationen werden die Informationspflichten im Folgenden im Einzelnen aufgeführt.</p>

            <p class="text-center">Unterabschnitt</p>
            <p class="text-center">Informationspflichten bei allen Versicherungszweigen</p>
            <p class="font-weight-regular">Der Versicherer hat Ihnen folgende Informationen zur Verfügung zu stellen:</p>
            <div class="font-weight-regular">
              <ol>
                <li>die Identität des Versicherers und der etwaigen Niederlassung, über die der Vertrag abgeschlossen werden soll; anzugeben ist auch das Handelsregister, bei dem der Rechtsträger eingetragen ist, und die zugehörige Registernummer;</li>
                <li>die ladungsfähige Anschrift des Versicherers und jede andere Anschrift, die für die Geschäftsbeziehung zwischen dem Versicherer und Ihnen maßgeblich ist, bei juristischen Personen, Personenvereinigungen oder -gruppen auch den Namen eines Vertretungsberechtigten; soweit die Mitteilung durch Übermittlung der Vertragsbestimmungen einschließlich der Allgemeinen Versicherungsbedingungen erfolgt, bedürfen die Informationen einer hervorgehobenen und deutlich gestalteten Form;</li>
                <li>die Hauptgeschäftstätigkeit des Versicherers;</li>
                <li>Angaben über das Bestehen eines Garantiefonds oder anderer Entschädigungsregelungen; Name und Anschrift des Garantiefonds sind anzugeben;</li>
                <li>die wesentlichen Merkmale der Versicherungsleistung, insbesondere Angaben über Art, Umfang und Fälligkeit der Leistung des Versicherers;</li>
                <li>den Gesamtpreis der Versicherung einschließlich aller Steuern und sonstigen Preisbestandteile, wobei die Prämien einzeln auszuweisen sind, wenn das Versicherungsverhältnis mehrere selbständige Versicherungsverträge umfassen soll, oder, wenn ein genauer Preis nicht angegeben werden kann, Angaben zu den Grundlagen seiner Berechnung, die Ihnen eine Überprüfung des Preises ermöglichen;</li>
                <li>Einzelheiten hinsichtlich der Zahlung und der Erfüllung, insbesondere zur Zahlungsweise der Prämien;</li>
                <li>die Befristung der Gültigkeitsdauer der zur Verfügung gestellten Informationen, beispielsweise die Gültigkeitsdauer befristeter Angebote, insbesondere hinsichtlich des Preises;</li>
                <li>Angaben darüber, wie der Vertrag zustande kommt, insbesondere über den Beginn der Versicherung und des Versicherungsschutzes sowie die Dauer der Frist, während der der Antragsteller an den Antrag gebunden sein soll;</li>
                <li>das Bestehen oder Nichtbestehen eines Widerrufsrechts sowie die Bedingungen, Einzelheiten der Ausübung, insbesondere Namen und Anschrift derjenigen Person, gegenüber der der Widerruf zu erklären ist, und die Rechtsfolgen des Widerrufs einschließlich Informationen über den Betrag, den Sie im Falle des Widerrufs gegebenenfalls zu zahlen haben; soweit die Mitteilung durch Übermittlung der Vertragsbestimmungen einschließlich der Allgemeinen Versicherungsbedingungen erfolgt, bedürfen die Informationen einer hervorgehobenen und deutlich gestalteten Form;</li>
                <li>a) Angaben zur Laufzeit des Vertrages;<br>b) Angaben zur Mindestlaufzeit des Vertrages;</li>
                <li>Angaben zur Beendigung des Vertrages; soweit die Mitteilung durch Übermittlung der Vertragsbestimmungen einschließlich der Allgemeinen Versicherungsbedingungen erfolgt, bedürfen die Informationen einer hervorgehobenen und deutlich gestalteten Form;</li>
                <li>die Mitgliedstaaten der Europäischen Union, deren Recht der Versicherer der Aufnahme von Beziehungen zu Ihnen vor Abschluss des Versicherungsvertrags zugrunde legt;</li>
                <li>das auf den Vertrag anwendbare Recht, eine Vertragsklausel über das auf den Vertrag anwendbare Recht oder über das zuständige Gericht;</li>
                <li>die Sprachen, in denen die Vertragsbedingungen und die in diesem Abschnitt genannten Vorabinformationen mitgeteilt werden, sowie die Sprachen, in denen sich der Versicherer verpflichtet, mit Ihrer Zustimmung die Kommunikation während der Laufzeit dieses Vertrags zu führen;</li>
                <li>einen möglichen Zugang für Sie zu einem außergerichtlichen Beschwerde- und Rechtsbehelfsverfahren und gegebenenfalls die Leistungsvoraussetzungen für diesen Zugang; dabei ist ausdrücklich darauf hinzuweisen, dass die Möglichkeit für Sie, den Rechtsweg zu beschreiten, hiervon unberührt bleibt;</li>
                <li>Name und Anschrift der zuständigen Aufsichtsbehörde sowie die Möglichkeit einer Beschwerde bei dieser Aufsichtsbehörde.</li>
              </ol>
            </div>

            <p>Ende der Widerrufsbelehrung</p>
          </div>

          <p>
            <strong>§2. Was haben Sie bei der Beitragszahlung zu beachten?</strong><br>
            Der Santander Protect-Beitrag wird als Einmalbeitrag durch Sie entrichtet. Haben Sie den Einmalbeitrag nicht vor Eintritt eines Versicherungsfalls gezahlt, sind wir nicht zur Leistung verpflichtet, es sei denn, Sie haben die Nichtzahlung nicht zu vertreten. Wir sind jedoch nur leistungsfrei, wenn Sie durch gesonderte Mitteilung in Textform auf diese Rechtsfolge der Nichtzahlung des Beitrages aufmerksam gemacht wurden.
          </p>
          <p>
            <strong>§3. Wann beginnt und endet der Versicherungsschutz?</strong><br>
          </p>
          <ol>
            <li>Der Versicherungsschutz zur Santander Protect Versicherung beginnt, vorbehaltlich bestehender Wartezeiten, mit dem Datum der Beitragszahlung, jedoch nicht vor Annahme des Antrages durch den Versicherer und dem im Versicherungsschein genannten Beginn.</li>
            <li>Eine Mindestlaufzeit für das Versicherungsverhältnis besteht nicht.</li>
            <li>Die Dauer des Versicherungsschutzes entspricht der im Versicherungsschein genannten Laufzeit oder endet, wenn Sie als Versicherungsnehmer Ihren Wohnsitz und dauernden Aufenthalt außerhalb der Bundesrepublik Deutschland verlegen.</li>
            <li>Der Versicherungsschutz für eine versicherte Person endet bereits vor der vereinbarten Dauer:
              <ol type="a">
                <li>wenn die versicherte Person dauerhaft voll erwerbsunfähig wird und daher nicht mehr als 15 Stunden die Woche einer Erwerbstätigkeit nachgehen kann</li>
                <li>wenn die versicherte Person Ihren Wohnsitz und dauernden Aufenthalt außerhalb der Bundesrepublik Deutschland verlegt</li>
                <li>mit Tod der versicherten Person oder mit Vollendung des 68. Lebensjahres.</li>
              </ol>
            </li>
          </ol>

          <div class="font-weight-bold bg-gray-lightest" style="padding: 10px">
            <p>
              <strong>§4. Welche Kündigungsmöglichkeiten des Versicherungsschutzes gibt es?</strong><br>
              Sie als Versicherungsnehmer können das Versicherungsverhältnis in Textform kündigen. Die Kündigung ist unter Einhaltung einer Kündigungsfrist von einem Monat zum Schluss eines jeden Monats zulässig.</p><p>Zur Wahrung der Frist genügt die rechtzeitige Absendung des Kündigungsverlangens in Textform (z.B. Brief oder E-Mail) an uns, die CNP Santander Insurance Europe DAC, Postfach 32 10 80, 40425 Düsseldorf.</p>
          </div>

          <p>
            <strong>§5. Was sind die Folgen einer vorzeitigen Beendigung des Versicherungsverhältnisses?</strong><br>
            Im Falle einer Kündigung des Versicherungsschutzes nach Ablauf der Widerrufsfrist sind die Beiträge bis zum Zeitpunkt der Beendigung des Versicherungsvertrages zu erbringen. Der Versicherungsvertrag und damit auch der Versicherungsschutz endet mit dem Zeitpunkt, zu dem die Kündigung wirksam wird, bzw. mit den in §3 Nr. 2 und 3 AVB- Santander Protect genannten Ereignissen.
          </p>
          <p><strong>Beitragsrückerstattung:</strong> Die Rückzahlung des Einmalbeitrages kann nicht verlangt werden. Im Falle einer vorzeitigen Beendigung des Versicherungsverhältnis durch Kündigung bzw. mit den in §3 Nr. 2 und 3 AVB- Santander Protect genannten Ereignissen erstatten wir nur den unverbrauchten Teil des Versicherungsbeitrags. Die Beitragsrückerstattung erfolgt anteilig (pro rata temporis) entsprechend dem Verhältnis zwischen der tatsächlichen und der vereinbarten Versicherungsdauer. Die Beitragsrückerstattung erfolgt zu Gunsten des Kontos, von dem der Einmalbeitrag eingegangen ist, es sei denn Sie nennen uns im Zusammenhang mit der Kündigungserklärung ein anderes Konto.
          </p>
          <p>
            <strong>§6. Welche Leistungsvoraussetzungen bestehen für den Versicherungsschutz?</strong><br>Versicherungsschutz wird nur gewährt, wenn bei Zustandekommen/Beginn des Versicherungsverhältnisses, die jeweilige zu versichernde Person mindestens 18 Jahre und höchsten 64 Jahre alt sind. Die versicherte Person sowie Sie (der Versicherungsnehmer) müssen ihren Wohnsitz und dauernden Aufenthalt in der Bundesrepublik Deutschland haben.
          </p>
          <p>
            <strong>§7. Zwei versicherte Personen</strong><br>Maximal eine weitere Person kann neben Ihnen (= der ersten versicherten Person) als zweite versicherte Person für Santander Protect mitversichert werden. Wenn eine zweite versicherte Person zum Versicherungsschutz hinzugefügt wurde, ist diese im Versicherungsschein mit aufgeführt.
          </p>
          <p>
            <strong>§8. Wo ist der Versicherungsschutz gültig?</strong><br>Der Versicherungsschutz gilt in der EU/EEA.
          </p>
          <p>
            <strong>§9. Was ist vor und nach Eintritt eines Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten)</strong><br>Sie haben bestimmte Obliegenheiten zu erfüllen, die im Einzelnen nachfolgend für alle versicherten Risiken sowie in den jeweiligen Besonderen Bedingungen nur für die dort erfassten Risiken geregelt sind.
          </p>
          <ol>
            <li>Sie sind verpflichtet, uns:
              <ol type="a">
                <li>die Änderungen des Wohnsitzes, Namens und / oder der Kontaktdaten des Versicherungsnehmers und der versicherten Person(en) unverzüglich mitzuteilen</li>
                <li>und den Eintritt des Versicherungsfalls unverzüglich mitzuteilen.</li>
              </ol>
            </li>
            <li>Zur Klärung der Leistungspflicht haben Sie uns auf unser Verlangen die erforderlichen Auskünfte zu erteilen. Entsprechende Nachweise können wir von Ihnen anfordern, wenn und soweit Ihnen deren Beschaffung billigerweise zugemutet werden kann. Sie tragen die, mit diesen Nachweisen, verbundenen Kosten.</li>
            <li>Sollte eine vertragliche Obliegenheit vorsätzlich verletzt werden, sind wir von der Verpflichtung zur Leistung frei. Bei grob fahrlässiger Obliegenheitsverletzung sind wir berechtigt, unsere Leistung in einem der Schwere des Verschuldens entsprechenden Verhältnis zu kürzen.</li>
            <li>Abweichend von § 9 Nr. 3 AVB- Santander Protect sind wir zur Leistung verpflichtet, soweit die Obliegenheit nicht arglistig verletzt wurde und die Verletzung der Obliegenheit weder für den Eintritt oder die Feststellung des Versicherungsfalles noch für die Feststellung oder den Umfang der Leistungspflicht des Versicherers ursächlich ist</li>
            <li>Über diese Rechtsfolgen werden Sie nach Eintritt des Versicherungsfalles noch einmal durch gesonderte Mitteilung in Textform hinweisen.</li>
          </ol>
          <p>
            <strong>§10. Wer ist der Empfänger der Versicherungsleistung?</strong><br>Leistungen aus dem Versicherungsverhältnis werden durch uns ausschließlich an die jeweilige versicherte Person, die den Versicherungsfall ausgelöst hat, ausgezahlt. Sie erklären diesbezüglich Ihr unwiderrufliches Einverständnis.
          </p>
          <p>
            <strong>§11. Wie ist das Verhältnis der einzelnen Leistungsbausteine zueinander?</strong></p>
          <ol>
            <li>Versicherungsleistungen aus den einzelnen Leistungsbausteinen schließen sich gegenseitig aus. Es besteht jeweils nur Anspruch auf eine der anspruchsberechtigten Leistungen aus den verschiedenen Leistungsbausteinen in dem gleichen Zeitraum. Die Leistung mit der höheren Leistung hat Vorrang.</li>
            <li>Während der Vertragslaufzeit hat eine versicherte Person einen Anspruch auf bis zu insgesamt 36 Zahlungen der monatlichen Versicherungssumme für die verschiedenen Leistungsbausteine. Dies beinhaltet nicht die einmalige Auszahlung des Leistungsbausteins für den Unfalltod.</li>
          </ol>

          <p>
            <strong>§12. Sanktionen</strong><br> Es besteht kein Anspruch auf Versicherungsleistung, wenn ein Bestandteil der Versicherung, der Leistungen, Tätigkeiten oder Geschäfte gegen geltende Sanktionen oder Regulierungen der Vereinten Nationen, der Europäischen Union oder in Deutschland geltenden bzw. Handelssanktionen oder -regulierungen verstößt.
          </p>
          <p>
            <strong>§13. Übertragbarkeit von Ansprüchen</strong><br>Sie, als der Versicherungsnehmer können Ihre Rechte oder Ansprüche aus dem Versicherungsverhältnis nicht auf Dritte übertragen oder an Dritte verpfänden.
          </p>
          <p>
            <strong>§14. Begriffsbestimmungen</strong></p>
          <p>    <strong>Karenzzeit:</strong> Leistungsfreie Zeit nach Eintritt des Versicherungsfalles, für die kein Leistungsanspruch geltend gemacht werden kann.
          </p>
          <p><strong>Monatliche Versicherungssumme:</strong>
            Der im Versicherungsschein genannte monatliche Betrag, der im Versicherungsfall geleistet wird. Sie: Der Versicherungsnehmer.</p>
          <p><strong>Versicherungsfall:</strong> Ein Ereignis, das bei Eintritt unsere Leistungspflicht (Versicherungsleistung) aus dem Versicherungsvertrag auslöst.</p>
          <p><strong>Versicherungsnehmer:</strong> Die Person, die den Versicherungsschutz beantragt und unser Vertragspartner im Versicherungsvertrag der Santander Protect Versicherung ist. Der Versicherungsnehmer ist im Versicherungsschein als Versicherungsnehmer aufgeführt.</p>
          <p><strong>Wartezeit:</strong> Zeitraum, für den kein Versicherungsschutz besteht und für den damit kein Leistungsanspruch geltend gemacht werden kann. Falls ein Leistungsbaustein eine Wartezeit hat, wird diese in den Besonderen Bedingungen des Leistungsbausteins ausgewiesen.</p>
          <p><strong>Wir:</strong> Der Versicherer, die CNP Santander Insurance Europe DAC</p>
          <p><strong>Qualifizierterer Arzt:</strong> Ein Arzt, dem es erlaubt ist, eine Behandlung durchzuführen, eine Diagnose zu erstellen und dies nach dem jeweiligen Stand von medizinscher Wissenschaft, Praxis, Kenntnissen, Wissen, Können und Aufmerksamkeit zu erbringen in der Lage ist.</p>

          <a class="anchor-link" name="step-3" id="step-3"></a>
          <h2>Besondere Bedingungen für den Leistungsbaustein Santander Protect bei Arbeitsunfähigkeit</h2>
          <p>
            <strong>§1. Was ist Gegenstand des Versicherungsschutzes?</strong>
          </p>
          <ol>
            <li>Der Leistungsbaustein bei Arbeitsunfähigkeit dient zur Absicherung des Einkommens der versicherten Person durch finanzielle Leistungen im Falle einer vorübergehenden Arbeitsunfähigkeit.</li>
            <li>Eine versicherte Arbeitsunfähigkeit liegt vor, wenn die versicherte Person infolge einer ärztlich nachgewiesenen Gesundheitsstörung nach Versicherungsbeginn vorübergehend vollständig außerstande ist, Ihre bisherige berufliche Tätigkeit auszuüben, sie auch nicht ausübt und keiner anderen Erwerbstätigkeit nachgeht.</li>
          </ol>
          <p>
            <strong>§2. Wer ist mit diesen Leistungsbaustein versichert?</strong><br>
            Die versicherte Person.
          </p>
          <p>
            <strong>§3. Welchen Zeitraum umfasst die Wartezeit?</strong><br>
            Die Wartezeit beträgt drei Monate ab Beginn des Versicherungsschutzes. Es besteht kein Versicherungsschutz, wenn die versicherte Person innerhalb der ersten drei Monate ab Beginn des Versicherungsschutzes arbeitsunfähig wird. Es besteht für den gesamten Zeitraum dieser Arbeitsunfähigkeit kein Versicherungsschutz. Die Wartezeit gilt nicht bei einer durch Unfall versursachten Arbeitsunfähigkeit, solange sich der Unfall während der Wartezeit ereignet hat.
          </p>
          <p>
            <strong>§4. Welchen Zeitraum umfasst die Karenzzeit?</strong><br>
            Die Karenzzeit beginnt mit Eintritt der Arbeitsunfähigkeit und beträgt im Leistungsfall 42 Tage, für die kein Leistungsanspruch besteht. Bei erneuter Arbeitsunfähigkeit gilt die Karenzzeit erneut.
          </p>
          <p>
            <strong>§5. Welche Leistungen erbringen wir, wenn Sie arbeitsunfähig werden, und für welche Dauer?</strong>
          </p>

          <ol>
            <li>Der Versicherungsschutz beginnt gemäß der in § 3 AVB- Santander Protect getroffenen Regelung, jedoch nicht vor dem Ablauf einer Wartezeit von drei Monaten.</li>
            <li>Im Versicherungsfall zahlen wir der versicherten Person die im Versicherungsschein ausgewiesene vereinbarte monatliche Versicherungssumme (Versicherungsleistung). Wir zahlen die monatliche Versicherungssumme nur aus, wenn die versicherte Person am ersten des jeweiligen Monats, weiterhin arbeitsunfähig ist.</li>
            <li>
              Wir leisten je Versicherungsfall, solange:
              <ul>
                <li>die versicherte Person arbeitsunfähig ist und Sie dies durch eine ärztlich attestierte Arbeitsunfähigkeitsbescheinigungen nachweisen (siehe § 8.2) und</li>
                <li>die versicherte Person keine teilweise oder volle Erwerbsminderungsrente erhält,</li>
              </ul>
              maximal jedoch für einen Leistungszeitraum von 12 Monaten nach Ablauf der Karenzzeit. Insgesamt gibt es je versicherter Person während der Vertragslaufzeit einen Anspruch auf bis zu insgesamt maximal 36 Monatszahlungen gemäß § 11 AVB &ndash; Santander Protect.
            </li>
            <li>Nach jeder Unterbrechung der Arbeitsunfähigkeit beginnt die Karenzzeit (Frist von 42 Tagen) erneut, in der kein Anspruch auf Arbeitsunfähigkeitsleistung besteht. Dies gilt auch, wenn die erneute Arbeitsunfähigkeit durch die gleiche Krankheitsart, wie bereits zuvor, verursacht worden ist.</li>
          </ol>
          <p>
            <strong>§6. Was passiert, wenn Sie den Versicherungsfall verspätet melden?</strong><br>
            Zeigen Sie als Versicherungsnehmer, dem Versicherer die Arbeitsunfähigkeit schuldhaft nicht unverzüglich nach Eintritt an (vgl. den nachfolgenden § 9 AVB- Santander Protect), wird die Versicherungsleistung erstmalig zum Zeitpunkt des auf die Anzeige folgenden Monats erbracht.
          </p>
          <p>
            <strong>§7. Welche Einschränkungen und Ausschlüsse der Leistungspflicht gibt es?</strong><br>
            Wir leisten nicht, wenn die Arbeitsunfähigkeit verursacht, ist:
          </p>
          <ol>
            <li>durch Alkoholismus oder eine Suchterkrankung (Drogen- oder Medikamentenmissbrauch) der versicherten Person oder durch einen Unfall, der infolge eines Rausches von der versicherten Person verursacht worden ist;</li>
            <li>direkt oder indirekt durch kriegerische Ereignisse, innere Unruhen oder Bürgeraufständen, sofern die versicherte Person aktiv teilgenommen hat;</li>
            <li>direkt oder indirekt durch Atom- oder Kernspaltung und/oder -fusion oder andere ähnliche Reaktionen oder radioaktive Kräfte oder Stoffe verursacht wurden;</li>
            <li>direkt oder indirekt durch den vorsätzlichen Einsatz von biologischen oder chemischen Waffen oder dem vorsätzlichen Einsatz oder der vorsätzlichen Freisetzung von biologischen oder chemischen Stoffen, sofern der Einsatz oder das Freisetzen darauf gerichtet sind, das Leben einer Vielzahl von Personen zu gefährden;</li>
            <li>durch Krankheiten und Verletzungen, die durch grobe Fahrlässigkeit oder absichtlich von der versicherten Person verursacht wurde, es sei denn es handelt sich dabei um:
              <ol type="a">
                <li>Folgen eines Selbstmordversuches der drei Jahre nach Beginn des Versicherungsschutzes verübt wurde</li>
                <li>oder folgen eines Selbstmordversuches, der nachweislich durch eine psychische Störung verursacht wurde, die den freien Willen ausschließt und nicht durch Alkohol-, Drogen- oder Medikamentenmissbrauch verursacht wurde</li>
                <li>oder vorsätzlich mit Zustimmung der versicherten Person verursacht wurden. Nicht beinhaltet in dieser Klausel ist die ärztliche Sterbehilfe, wenn die versicherte Person palliativ betreut wird und an einer unheilbaren Krankheit leidet.</li>
              </ol>
            </li>
            <li>durch Begehung oder den Versuch einer Straftat oder illegalen Handlung durch die versicherte Person;</li>
            <li>durch Folgen einer medizinisch nicht notwendigen Behandlung oder direkt durch ärztliche Fehler im Rahmen einer medizinisch notwendigen Heilbehandlung.</li>
          </ol>

          <p>
            <strong>§8. Was ist vor und nach Eintritt des Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten) </strong><br>
            Zusätzlich zu den in § 9 AVB &ndash; Santander Protect aufgeführten <strong>Obliegenheiten</strong> gilt folgendes:
          </p>
          <ol>
            <li>Der Eintritt einer den leistungsfreien Zeitraum (Karenzzeit) von 42 Tagen übersteigenden Arbeitsunfähigkeit ist uns unverzüglich anzuzeigen.</li>
            <li>Zum Nachweis der Arbeitsunfähigkeit müssen Sie uns eine ärztlich attestierte Arbeitsunfähigkeitsbescheinigung und auf unser Verlangen ein ärztliches Zeugnis auf unserem Original-Vordruck einreichen. Die Arbeitsunfähigkeitsbescheinigung muss die zugrunde liegenden Diagnosen und die voraussichtliche Dauer der Arbeitsunfähigkeit enthalten sowie den Anforderungen, gemäß der &ldquo;Arbeitsunfähigkeits-Richtlinie&rdquo; des Gemeinsamen Bundesausschusses&ldquo; entsprechen. Wir können zudem eine medizinische Untersuchung von der versicherten Person durch einen von uns bestimmten, zugelassenen und praktizierenden Arzt verlangen.</li>
            <li>Wir können &ndash; dann allerdings auf unsere Kosten &ndash; weitere notwendige Nachweise verlangen und erforderliche Erhebungen selbst anstellen. Wir können, auch wenn wir bereits Versicherungsleistungen erbringen, weitere Nachweise verlangen, dass die Leistungsvoraussetzungen unserer Leistungspflicht noch immer erfüllt sind. Für diese weiteren Nachweise gilt § 9 Nr. 2 der AVB- Santander Protect entsprechend</li>
            <li>Wurden Leistungsansprüche wegen der Arbeitsunfähigkeit der versicherten Person geltend gemacht, sind Sie verpflichtet, uns die Wiederherstellung der Arbeitsfähigkeit unverzüglich anzuzeigen.</li>
            <li>Bei Arbeitsunfähigkeit aufgrund einer psychischen Erkrankung muss diese nach den ersten drei Krankheitsmonaten von einem niedergelassenen Facharzt für Psychiatrie und Psychotherapie, Facharzt für Psychosomatische Medizin und Psychotherapie oder Facharzt für Kinder- und Jugendpsychiatrie und Psychotherapie bescheinigt werden.</li>
            <li>Bei Verletzung einer der Obliegenheiten nach den vorgenannten Absätzen gilt 9 Nr. 3 &ndash; 5 der AVB- Santander Protect.</li>
          </ol>

          <a class="anchor-link" name="step-5" id="step-5"></a>
          <h2>Besondere Bedingungen für den Leistungsbaustein Santander Protect bei Unfalltod</h2>

          <p>
            <strong>§1. Was ist Gegenstand des Versicherungsschutzes und was ist die Versicherungsleistung?</strong><br>
          </p>

          <ol>
            <li>Stirbt die versicherte Person an den Folgen eines Unfalls, zahlen wir die im Versicherungsschein genannte einmalige Versicherungssumme. Der Unfall und der aus dem Unfall resultierende Tod muss während der Versicherungsdauer eingetreten sein. Zwischen dem Unfall und der aus dem Unfall resultierende Tod darf nicht mehr als ein Jahr vergangen sein.</li>
            <li>Der Unfall muss die Hauptursache des Todes der versicherten Person sein.</li>
            <li>Ein Unfalltod liegt vor, wenn die versicherte Person durch ein plötzlich von außen auf ihren Körper wirkendes Ereignis (Unfallereignis) unfreiwillig eine zum Tode führende Gesundheitsschädigung erleidet.</li>
          </ol>

          <p>
            <strong>§2. Wer ist mit diesen Leistungsbaustein versichert?</strong><br>
            Die versicherte Person.
          </p>
          <p>
            <strong>§3. Welche Einschränkungen und Ausschlüsse der Leistungspflicht gibt es?</strong><br>
            Unsere Leistungspflicht besteht nicht
          </p>
          <ol type="a">
            <li>Bei Unfalltod der versicherten Person in unmittelbarem oder mittelbarem Zusammenhang mit risikotragenden Sportarten für die nach deutschem Recht eine Genehmigung oder Erlaubnis benötigt wird, wie der lizenzpflichtige Motorsport oder Luftsport.</li>
            <li>Bei Unfalltod der versicherten Person in unmittelbarem oder mittelbarem Zusammenhang durch die Ausübung von professionellem Sport.</li>
            <li>Bei Unfalltod der versicherten Person in unmittelbarem oder mittelbarem Zusammenhang mit kriegerischen Ereignissen besteht keine Leistungspflicht. Dies gilt nicht, wenn die versicherte Person in unmittelbarem oder mittelbarem Zusammenhang mit kriegerischen Ereignissen stirbt, denen sie während eines Aufenthaltes außerhalb Deutschlands ausgesetzt und an denen sie nicht aktiv beteiligt war.</li>
            <li>Bei Unfalltod der versicherten Person durch grobfahrlässiges, kriminelles oder illegales Handeln bzw. Handlungen der versicherten Person oder des Versicherungsnehmers.</li>
            <li>Bei Unfalltod der versicherten Person in unmittelbarem oder mittelbarem Zusammenhang mit dem vorsätzlichen Einsatz von atomaren, biologischen oder chemischen Waffen oder dem vorsätzlichen Einsatz oder der vorsätzlichen Freisetzung von radioaktiven, biologischen oder chemischen Stoffen besteht keine Leistungspflicht des Versicherers, sofern der Einsatz oder das Freisetzen darauf gerichtet sind, das Leben einer Vielzahl von Personen zu gefährden.</li>
            <li>Im Falle der vorsätzlichen Selbsttötung der versicherten Person vor Ablauf von 3 Jahren nach Abschluss des Versicherungsvertrages besteht keine Leistungspflicht des Versicherers, sofern die Tat nicht in einem die freie Willensbestimmung ausschließenden Zustand krankhafter Störung der Geistestätigkeit begangen worden ist oder durch medizinisch indizierte Sterbehilfe.</li>
          </ol>

          <p>
            <strong>§4. Was ist vor und nach Eintritt des Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten)?</strong><br>
            Zusätzlich zu den in  § 9 AVB-Santander Protect aufgeführten Obliegenheiten gilt folgendes:
          </p>

          <ol>
            <li>Der Tod der versicherten Person ist dem Versicherer unverzüglich anzuzeigen. Folgende Unterlagen sind einzureichen:
              <ul>
                <li>eine amtliche, Alter und Geburtsort enthaltende Sterbeurkunde,</li>
                <li>ein amtliches Zeugnis über die Todesursache, die zum Tode der versicherten Person geführt hat.</li>
              </ul>
              Dies gilt nur, wenn die Beschaffung der Unterlagen billigerweise zumutbar ist.
            </li>
            <li>Bei Verletzung einer der Obliegenheiten nach den vorgenannten Absätzen gilt § 9 Nr. 3 &ndash; 5 AVB-Santander Protect.</li>
          </ol>

          <h2>Für Arbeitnehmer:</h2>
          <a class="anchor-link" name="step-4" id="step-4"></a>

          <h2>Besondere Bedingungen für den Leistungsbaustein Santander Protect bei Arbeitslosigkeit</h2>

          <p>
            <strong>§1. Was ist Gegenstand des Versicherungsschutzes?</strong>
          </p>

          <ol>
            <li>Der Leistungsbaustein bei Arbeitslosigkeit dient zur Absicherung des Einkommens der versicherten Person durch finanzielle Leistungen im Falle einer unfreiwilligen Arbeitslosigkeit.</li>
            <li>Eine versicherte Arbeitslosigkeit liegt vor, wenn die versicherte Person aus einer Beschäftigung (siehe nachfolgend Nr. 4) heraus während der Dauer der Versicherung unverschuldet arbeitslos wird, keiner sozialversicherungspflichtigen Tätigkeit nachgeht, bei der zuständigen Agentur für Arbeit als arbeitslos gemeldet ist, Arbeitslosengeld wegen Arbeitslosigkeit bezieht und sich aktiv um Arbeit bemüht. Letzteres setzt voraus, dass die versicherte Person den Anforderungen der Agentur für Arbeit bzgl. der Berechtigung des Bezuges von Arbeitslosengeld nachkommt.</li>
            <li>Bei Verlust der Beschäftigung muss die Arbeitslosigkeit Folge einer Kündigung des Arbeitgebers oder einer einvernehmlichen Aufhebung des Arbeitsverhältnisses im Rahmen der vergleichsweisen Erledigung eines Kündigungsschutz-Prozesses oder zur Abwendung einer betriebsbedingten Kündigung sein. Kündigungen, die die versicherte Person ausgesprochen hat und Kündigungen bzw. Vertragsaufhebungen, die vor Beginn des Versicherungsschutzes oder während der Wartezeit ausgesprochen werden, begründen keinen Versicherungsfall.</li>
            <li>Versicherungsschutz wird nur gewährt, wenn die versicherte Person bis zum Eintritt der Arbeitslosigkeit mindestens seit sechs Monaten fortdauernd bei demselben Arbeitgeber als Arbeitnehmer im Sinne dieser Bedingungen beschäftigt (angestellt) war.</li>
          </ol>
          <p>
            <strong>§2. Wer ist mit diesen Leistungsbaustein versichert?</strong><br>
            Die versicherte Person gemäß dem Versicherungsschein, die beim Eintritt des Versicherungsfalls Arbeitnehmer im Sinne dieser Bedingungen ist.
          </p>
          <p>
            <strong>§3. Wann ist die versicherte Person Arbeitnehmer im Sinne dieser Bedingungen?</strong><br>
            Die versicherte Person gilt als Arbeitnehmer, wenn sie einer angestellten und bezahlten Beschäftigung nachgeht. Die Beschäftigung muss zudem ein sozialversicherungspflichtiges Arbeitsverhältnis von mehr als 15 Stunden pro Woche sein.
          </p>
          <p>
            <strong>Die versicherte Person gilt jedoch <u>nicht</u> als Arbeitnehmer,</strong> wenn sie folgende Arbeitsverhältnisse oder Tätigkeiten ausübt:
          </p>
          <ul>
            <li>Saisonarbeiten; projektgebundene Arbeiten, für die sie speziell angestellt wurde; Arbeitsverträge während der Probezeit und Ausbildungszeiten; saisonale Kurzarbeit (gemäß § 101 SGB III); sowie Personen, die bei Ehegatten oder in direkter Linie Verwandten beschäftigt sind.</li>
            <li>Selbstständige; Beamte; Richter; Pensionäre; Bundesfreiwilligendienst; Berufssoldaten, Zeitsoldaten.</li>
          </ul>

          <p>
            <strong>§4. Welchen Zeitraum umfasst die Wartezeit?</strong><br>
            Die Wartezeit beträgt <strong>drei Monate</strong> ab Beginn des Versicherungsschutzes. Es besteht kein Versicherungsschutz, wenn die versicherte Person:</p>
          <ul>
            <li>vor oder innerhalb der ersten drei Monate ab Beginn des Versicherungsschutzes ihre Arbeit verliert oder</li>
            <li>aus einer Kurzarbeit heraus ihre Arbeit verliert, die vor oder innerhalb der ersten drei Monate ab Beginn des Versicherungsschutzes begann.</li>
          </ul>
          <p>Es besteht für den gesamten Zeitraum dieser Arbeitslosigkeit kein Versicherungsschutz.</p>

          <p>
            <strong>§5. Welchen Zeitraum umfasst die Karenzzeit?</strong><br>
            Die Karenzzeit beginnt mit Eintritt der Arbeitslosigkeit und beträgt im Leistungsfall zwei Monate, für die kein Leistungsanspruch besteht.
          </p>
          <p>
            Bei wiederholter Arbeitslosigkeit innerhalb der Vertragslaufzeit gilt die Karenzzeit erneut.
          </p>
          <p>
            <strong>§6. Welche Leistungen erbringen wir, wenn die versicherte Person unverschuldet arbeitslos wird, und für welche Dauer?</strong>
          </p>
          <ol>
            <li>Der Versicherungsschutz beginnt gemäß der in § 3 AVB- Santander Protect getroffenen Regelung, jedoch nicht vor dem Ablauf der Wartezeit von drei Monaten.</li>
            <li>Im Versicherungsfall leisten wir ab dem dritten Monat nach Beginn der Arbeitslosigkeit (nach Ablauf der zweimonatigen Karenzzeit) die im Versicherungsschein ausgewiesene vereinbarte monatliche Versicherungssumme (Versicherungsleistung). Wir zahlen die monatliche Versicherungssumme nur aus, wenn die versicherte Person am ersten des jeweiligen Monates, weiterhin arbeitslos ist.</li>
            <li>
              Wir leisten je Versicherungsfall, solange die versicherte Person:
              <ul>
                <li>bei der zuständigen Agentur für Arbeit als arbeitslos gemeldet ist,</li>
                <li>Arbeitslosengeld wegen Arbeitslosigkeit von der Agentur für Arbeit bezieht,</li>
                <li>und sich aktiv um Arbeit bemüht,</li>
              </ul>
              maximal jedoch für einen Leistungszeitraum von 12 Monaten nach Ablauf der Karenzzeit. Insgesamt gibt es je versicherter Person während der Vertragslaufzeit einen Anspruch auf bis zu insgesamt maximal 36 Monatszahlungen gemäß § 11 AVB &ndash; Santander Protect.
            </li>
            <li>Die Leistungsdauer bei befristeten Arbeitsverhältnissen ist begrenzt durch die Dauer der Befristung. Ist die Arbeitslosigkeit durch die Befristung des Arbeitsvertrages eingetreten, besteht kein Leistungsanspruch.</li>
            <li>Mehrfache Arbeitslosigkeit ist versichert. Im Falle der erneuten Arbeitslosigkeit müssen die Anspruchsvoraussetzungen des vorstehenden §1 und folgende Bedingungen der Besondere Bedingungen bei Arbeitslosigkeit erfüllt sein, insbesondere muss die versicherte Person auch bei Beginn eines erneuten Versicherungsfalls mindestens sechs Monate durchgehend bei demselben (gegebenenfalls neuen) Arbeitgeber vollzeitbeschäftigt gewesen sein (mindestens 15 Stunden pro Woche).</li>
            <li>Sollte die versicherte Person vor dem Ende der maximalen Leistungsdauer von 12 Monate eine befristete Tätigkeit aufnehmen, und erneut eine unverschuldete Arbeitslosigkeit im Anschluss dieser befristeten Tätigkeit eintreten, so werden wir die Leistungszahlung ohne eine erneute Karenzzeit (Frist von 2 Monaten) wieder aufnehmen, jedoch nur bis zu dem Zeitpunkt, an dem die maximale Leistungsdauer von 12 Monaten des ursprünglichen Arbeitslosigkeitsfalles erreicht ist.</li>
          </ol>

          <p>
            <strong>§7. Was passiert, wenn Sie den Versicherungsfall verspätet melden?</strong><br>
            Zeigen Sie uns den Versicherungsfall schuldhaft nicht unverzüglich nach Ablauf der Karenzzeit (Frist von 2 Monaten) an (vgl. § 9 Nr.1 Besondere Bedingungen bei Arbeitslosigkeit) wird die Versicherungsleistung erstmalig zum Zeitpunkt des auf die Anzeige folgenden Monats erbracht.
          </p>
          <p>
            <strong>§8. Welche Einschränkungen und Ausschlüsse der Leistungspflicht gibt es?</strong><br>
            Wir leisten nicht, wenn die versicherte Person:
          </p>
          <ol type="a">
            <li>und/oder Sie bei Versicherungsbeginn von der bevorstehenden Beendigung des Arbeitsverhältnisses Kenntnis hatten oder aufgrund grober Fahrlässigkeit keine Kenntnis hatten;</li>
            <li>durch Alkoholismus oder eine Suchterkrankung (Drogen- oder Medikamentenmissbrauch) oder durch einen Unfall infolge eines Rausches;</li>
            <li>direkt oder indirekt durch kriegerische Ereignisse oder innere Unruhen, sofern die versicherte Person aktiv teilgenommen hat;</li>
            <li>die direkt oder indirekt durch Atom- oder Kernspaltung und / oder -fusion oder andere ähnliche Reaktionen oder radioaktive Kräfte oder Stoffe verursacht wurden.</li>
            <li>direkt oder indirekt durch den vorsätzlichen Einsatz von biologischen oder chemischen Waffen oder dem vorsätzlichen Einsatz oder der vorsätzlichen Freisetzung von biologischen oder chemischen Stoffen, sofern der Einsatz oder das Freisetzen darauf gerichtet sind, das Leben einer Vielzahl von Personen zu gefährden;</li>
            <li>durch Begehung oder den Versuch einer Straftat oder illegalen Handlung durch die versicherte Person;</li>
            <li>auf Grund eines vorsätzlichen Fehlverhaltens oder aufgrund grober Fahrlässigkeit gekündigt wurde;</li>
            <li>aus den folgenden Arbeitsverhältnissen oder Tätigkeiten heraus arbeitslos werden: Saisonarbeiten; projektgebundene Arbeiten, für die die versicherte Person speziell angestellt wurden; Arbeitsverträge während der Probezeit und Ausbildungszeiten; saisonale Kurzarbeit (gemäß § 101 SGB III); sowie Personen, die bei Ehegatten oder in direkter Linie Verwandten beschäftigt sind.</li>
            <li>aus einem nicht voll sozialversicherungspflichtigen Arbeitsverhältnis arbeitslos werden. (z.B. Beamte; Selbstständige; Freiberufler; Berufs- und Zeitsoldaten; geringfügige Beschäftigungsverhältnisse) oder</li>
            <li>durch Ablauf eines befristeten Arbeitsverhältnisses arbeitslos werden.</li>
          </ol>

          <p>
            <strong>§9. Was ist vor und nach Eintritt des Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten)?</strong><br>
            Zusätzlich zu den in § 9 AVB- Santander Protect aufgeführten Obliegenheiten gilt Folgendes:
          </p>
          <ol>
            <li>
              Dauert die Arbeitslosigkeit nach Ablauf der Karenzzeit an (Frist von 2 Monaten), haben Sie uns:
              <ol type="a">
                <li>Beginn und Ende der unverschuldeten Arbeitslosigkeit unverzüglich in Textform anzuzeigen,</li>
                <li>das mit Kündigungsgründen versehenes Kündigungsschreiben oder eine Bescheinigung des Arbeitgebers über den Kündigungsgrund vorzulegen,</li>
                <li>eine vom letzten Arbeitgeber für die Agentur für Arbeit ausgefüllte Arbeitsbescheinigungskopie vorzulegen und</li>
                <li>eine Bescheinigung der Agentur für Arbeit vorzulegen, aus der sich ergibt, seit wann die versicherte Person bei der Agentur für Arbeit ununterbrochen als arbeitslos und als arbeitsuchend gemeldet ist.</li>
              </ol>
            </li>
            <li>
              Während der Leistungsdauer haben Sie uns:
              <ol type="a">
                <li>den ersten und jeden weiteren Bewilligungs- oder Änderungsbescheid der Agentur für Arbeit unverzüglich vorzulegen, aus dem sich die Dauer des Bezuges von Arbeitslosengeld ergibt,</li>
                <li>sowohl jegliche eventuelle Reduzierung des Arbeitslosengeldes oder des Anspruchs als auch die nachträgliche Aberkennung des Arbeitslosengeldanspruchs durch die Agentur für Arbeit unverzüglich mitzuteilen, und</li>
                <li>das ununterbrochene Fortbestehen der unverschuldeten Arbeitslosigkeit, den regelmäßigen Bezug von Arbeitslosengeld und die aktiven Bemühungen, um eine neue Arbeitsstelle jeden Monat unverzüglich nachzuweisen.</li>
              </ol>
            </li>
            <li>Sofern Sie uns die oben genannten Belege (Nachweise/Bescheinigungen) vorzulegen haben, gilt dies nur, wenn Ihnen die Beschaffung billigerweise zugemutet werden kann.</li>
            <li>Sie sind verpflichtet, uns den Eintritt der versicherten Person in den endgültigen Ruhestand oder Vorruhestand unverzüglich anzuzeigen.</li>
            <li>Bei Verletzung einer der Obliegenheiten nach den vorgenannten Absätzen gilt  § 9 Nr. 3 &ndash; 5 AVB- Santander Protect. </li>
          </ol>

          <h2>Für Arbeitnehmer:</h2>
          <a class="anchor-link" name="step-6" id="step-6"></a>

          <h2>Besondere Bedingungen für den Leistungsbaustein Santander Protect bei konjunktureller Kurzarbeit</h2>


          <p>
            <strong>§1. Was ist Gegenstand des Versicherungsschutzes und was ist die Versicherungsleistung?</strong><br>
            Der Leistungsbaustein Santander Protect bei konjunktureller Kurzarbeit sichert die versicherte Person als Arbeitnehmer (gemäß § 3) finanziell bei betriebsbedingter konjunktureller Kurzarbeit ab. Die Kurzarbeit muss der Arbeitgeber der versicherten Person aus wirtschaftlichen Gründen verlangt haben und sie muss Anspruch auf Kurzarbeitergeld nach § 95 SGB III haben.
          </p>
          <p>
            <strong>§2. Wer ist mit diesen Leistungsbaustein versichert?</strong><br>
            Die versicherte Person gemäß dem Versicherungsschein, die beim Eintritt des Versicherungsfalls Arbeitnehmer im Sinne dieser Bedingungen ist.
          </p>
          <p>
            <strong>§3. Wann ist die versicherte Person Arbeitnehmer im Sinne dieser Bedingungen?</strong><br>
            Die versicherte Person gilt als Arbeitnehmer, wenn sie einer angestellten und bezahlten Beschäftigung nachgeht. Die Beschäftigung muss zudem ein sozialversicherungspflichtiges Arbeitsverhältnis von mehr als 15 Stunden pro Woche sein.
            <strong>Die versicherte Person gilt jedoch <u>nicht</u> als Arbeitnehmer</strong>, wenn sie folgende Arbeitsverhältnisse oder Tätigkeiten ausübt:
          </p>
          <ul>
            <li>Saisonarbeiten; projektgebundene Arbeiten, für die sie speziell angestellt wurde; Arbeitsverträge während der Probezeit und Ausbildungszeiten; saisonale Kurzarbeit (gemäß § 101 SGB III); sowie Personen, die bei Ehegatten oder in direkter Linie Verwandten beschäftigt sind.</li>
            <li>Selbstständige; Beamte; Richter; Pensionäre; Bundesfreiwilligendienst; Berufssoldaten, Zeitsoldaten.</li>
          </ul>

          <p>
            <strong>§4. Welchen Zeitraum umfasst die Wartezeit?</strong><br>
            Die Wartezeit beträgt <strong>drei Monate</strong> ab Beginn des Versicherungsschutzes. Dies bedeutet, dass kein Versicherungsschutz besteht, soweit die versicherte Person innerhalb der ersten drei Monate ab Beginn des Versicherungsschutzes aufgrund betriebsbedingter konjunktureller Kurzarbeit, Kurzarbeitergeld bezieht. Die versicherte Person hat für den gesamten Zeitraum dieser Kurzarbeit keinen Versicherungsschutz. Eine betriebsbedingte konjunkturelle Kurzarbeit ist erst versichert, wenn diese nach der Wartezeit eintritt.
          </p>
          <p>
            <strong>§5. Welche Leistungen erbringen wir bei konjunktureller Kurzarbeit?</strong><br>
            Im Versicherungsfall zahlen wir ab Beginn der Kurzarbeit. Wir zahlen die monatliche Versicherungssumme nur aus, wenn die die versicherte Person am ersten des jeweiligen Monates, weiterhin in Kurzarbeit sind, für maximal 12 Monate. Insgesamt gibt es je versicherter Person während der Vertragslaufzeit einen Anspruch auf bis zu insgesamt maximal 36 Monatszahlungen gemäß  § 11 AVB &ndash; Santander Protect.
          </p>
          <p>
            <strong>§6. Welche Einschränkungen und Ausschlüsse der Leistungspflicht gibt es?</strong><br>
            Keine Leistungspflicht besteht bei Kurzarbeit im Zusammenhang mit:
          </p>
          <ol type="a">
            <li>Freiwilliger Kurzarbeit,</li>
            <li>Altersteilzeit,</li>
            <li>Streik (Arbeitskampf bedingter Kurzarbeit nach § 100 SGB III) oder </li>
            <li>Saisonale Kurzarbeit nach § 101 SGB III.</li>
          </ol>

          <p>
            <strong>§7. Was ist vor und nach Eintritt des Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten)?</strong><br>
            Zusätzlich zu den in § 9 AVB-Santander Protect aufgeführten <strong>Obliegenheiten</strong> gilt folgendes:
          </p>

          <ol>
            <li>Das Eintreten von konjunktureller Kurzarbeit ist uns unverzüglich anzuzeigen.</li>
            <li>Folgende Unterlagen sind einzureichen:
              <ul>
                <li>Bestätigung des Arbeitgebers mit Start- und Enddatum der Kurzarbeit und Grund der Kurzarbeit</li>
                <li>Bestätigung des Jobcenters über die Anerkennung der Kurzarbeit nach § 95 SGB III mit Start- und Enddatum,</li>
              </ul>
              sofern die Beschaffung der Unterlagen billigerweise zumutbar ist.
            </li>
            <li>Während der Leistungsdauer haben Sie uns das Bestehen der Kurzarbeit nachzuweisen und uns jegliche Änderungen bzgl. der Kurzarbeit mitzuteilen.</li>
            <li>Bei Verletzung einer der Obliegenheiten nach den vorgenannten Absätzen gilt § 9 Nr. 3 &ndash; 5 AVB- Santander Protect.</li>
          </ol>

          <h2>Für Arbeitnehmer:</h2>
          <a class="anchor-link" name="step-7" id="step-7"></a>

          <h2>Besondere Bedingungen für den Leistungsbaustein Santander Protect bei Elternzeit oder Pflege eines nahen Verwandten</h2>

          <p>
            <strong>§1. Was ist Gegenstand des Versicherungsschutzes und was ist die Versicherungsleistung?</strong><br>

            Der Leistungsbaustein Santander Protect bei Elternzeit oder Pflege eines nahen Verwandten sichert die versicherte Person finanziell ab, wenn sie als Arbeitnehmer im Sinne dieser Bedingungen (gemäß § 3) Elternzeit (gemäß Bundeselterngeld- und Elternzeitgesetz) nehmen oder sich aufgrund eines pflegebedürftigen nahen Verwandten (gemäß Pflegezeit- bzw. Familenpflegezeitgesetz) ganz oder teilweise von der Arbeit freistellen lässt. Wir zahlen die monatliche Versicherungssumme nur aus, wenn die versicherte Person am ersten des jeweiligen Monates, weiterhin die Elternzeit beziehungsweise Pflegezeit in Anspruch nehmen bis zu 6 Monate pro Versicherungsfall. Wir zahlen jedoch nur für die Dauer der tatsächlichen Freistellung. Insgesamt gibt es je versicherter Person während der Vertragslaufzeit einen Anspruch auf bis zu insgesamt maximal 36 Monatszahlungen gemäß § 11 AVB &ndash; Santander Protect.
          </p>
          <p>
            <strong>§2. Wer ist mit diesen Leistungsbaustein versichert?</strong><br>
            Die versicherte Person gemäß dem Versicherungsschein, die beim Eintritt des Versicherungsfalls Arbeitnehmer im Sinne dieser Bedingungen ist.
          </p>
          <p>
            <strong>§3. Wann ist die versicherte Person Arbeitnehmer im Sinne dieser Bedingungen?</strong><br>
            Die versicherte Person gilt als Arbeitnehmer, wenn sie einer angestellten und bezahlten Beschäftigung nachgeht. Die Beschäftigung muss zudem ein sozialversicherungspflichtiges Arbeitsverhältnis von mehr als 15 Stunden pro Woche sein.
          </p>
          <p><strong>Die versicherte Person gilt jedoch <u>nicht</u> als Arbeitnehmer</strong>, wenn sie folgende Arbeitsverhältnisse oder Tätigkeiten ausübt:</p>
          <ul>
            <li>Saisonarbeiten; projektgebundene Arbeiten, für die sie speziell angestellt wurde; Arbeitsverträge während der Probezeit und Ausbildungszeiten; saisonale Kurzarbeit (gemäß § 101 SGB III); sowie Personen, die bei Ehegatten oder in direkter Linie Verwandten beschäftigt sind.</li>
            <li>Selbstständige; Beamte; Richter; Pensionäre; Bundesfreiwilligendienst; Berufssoldaten, Zeitsoldaten.</li>
          </ul>

          <p>
            <strong>§4. Welchen Zeitraum umfasst die Wartezeit?</strong><br>
            Die Wartezeit beträgt <strong>neun Monate</strong> ab Beginn des Versicherungsschutzes. Dies bedeutet, sollte innerhalb der ersten neun Monate nach Beginn des Versicherungsschutzes die Pflege eines nahen Verwandten bzw. Elternzeit eintreten, ist dies nicht versichert. Die versicherte Person hat für den gesamten Zeitraum dieses Ereignisses keinen Versicherungsschutz. Die Pflege eines nahen Verwandten bzw. Elternzeit ist erst versichert, wenn der damit verbundene Versicherungsfall nach der Wartezeit erstmals eintritt.
          </p>
          <p>
            <strong>§5. Was sind die Leistungsvoraussetzungen des Versicherungsschutzes bei Elternzeit?</strong><br>
            Für einen Leistungsanspruch müssen die folgenden Bedingungen erfüllt sein:
          </p>
          <ol type="a">
            <li>Das Kind der versicherten Person muss nach Ablauf der Wartezeit geboren sein oder adoptiert werden.</li>
            <li>Die versicherte Person muss Arbeitnehmer im Sinne dieser Bedingungen sein.</li>
            <li>Anspruch auf die Zahlung der im Versicherungsschein genannten monatlichen Versicherungssumme hängt von der Inanspruchnahme der Elternzeit ab.</li>
            <li>Die versicherte Person bezieht Elterngeld nach dem Bundeselterngeld- und Elternzeitgesetz.</li>
            <li>Die Arbeitszeit der versicherten Person muss aufgrund der Elternzeit verkürzt sein.</li>
            <li>Die versicherte Person geht keiner Beschäftigung von mehr als 30 Stunden pro Woche nach.</li>
          </ol>

          <p>
            <strong>§6. Was sind die Leistungsvoraussetzungen bei ganz oder teilweiser Freistellung von der Arbeit für die Pflege oder Organisation der Pflege eines nahen Verwandten? </strong><br>
            Für einen Leistungsanspruch müssen die folgenden Bedingungen erfüllt sein:
          </p>
          <ol type="a">
            <li>Die versicherte Person muss Arbeitnehmer im Sinne dieser Bedingungen sein.</li>
            <li>Ihr Anspruch auf die Zahlung der im Versicherungsschein genannten monatlichen Versicherungssumme hängt von der Inanspruchnahme von der staatlich geförderten Aufstockung des Arbeitsentgelts während der Pflegezeit bzw. Familienpflegezeit ab.</li>
            <li>Die Arbeitszeit muss aufgrund der häuslichen Pflege oder der Umstrukturierung der Pflege eines nahen Verwandten reduziert sein.</li>
            <li>Die versicherte Person geht keiner Beschäftigung von mehr als 30 Stunden pro Woche nach.</li>
          </ol>

          <p>
            <strong>§7. Welche Einschränkungen und Ausschlüsse der Leistungspflicht gibt es?</strong><br>
            Es besteht kein Leistungsanspruch bei Pflegekindern.
          </p>
          <p>
            <strong>§8. Was ist vor und nach Eintritt des Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten)?</strong><br>
            Zusätzlich zu den in § 9 AVB-Santander Protect aufgeführten Obliegenheiten gilt Folgendes:
          </p>
          <ol>
            <li>
              Das Eintreten des Versicherungsfalls ist uns unverzüglich anzuzeigen.
              <ol type="a">
                <li>Bei Inanspruchnahme wegen Elternzeit sind folgende Unterlagen einzureichen:
                  <ul>
                    <li>Benachrichtigung über den Bezug des Elterngeldes.</li>
                    <li>Eine Kopie der Geburtsurkunde oder des Adoptionsnachweises.</li>
                  </ul>
                </li>
                <li>Bei Inanspruchnahme wegen Pflegezeit bzw. Familienpflegezeit sind folgende Unterlagen einzureichen:
                  <ul>
                    <li>Bestätigung des Arbeitgebers mit Beginn- und Enddatum der Pflegezeit bzw. Familienpflegezeit Die genannten Unterlagen sind einzureichen, wenn die Beschaffung der Unterlagen billigerweise zumutbar ist.</li>
                  </ul>
                </li>
              </ol>
            </li>
            <li>Während der Leistungsdauer haben Sie uns das Bestehen der Pflegezeit bzw. Familienpflegezeit oder Elternzeit nachzuweisen jegliche Änderungen in Bezug auf den Zeitraum der Pflegezeit bzw. Familienpflegezeit oder Elternzeit mitzuteilen.</li>
            <li>Bei Verletzung einer der Obliegenheiten nach den vorgenannten Absätzen gilt § 9 Nr. 3 &ndash; 5 AVB-Santander Protect.</li>
          </ol>

          <h2>Für Beamter, Selbstständige und Rentner:</h2>
          <a class="anchor-link" name="step-8" id="step-8"></a>
          <h2>Besondere Bedingungen für den Leistungsbaustein Santander Protect bei schweren Krankheiten</h2>

          <p>
            <strong>§1. Was ist Gegenstand des Versicherungsschutzes und was ist die Versicherungsleistung?</strong><br>
            Der Leistungsbaustein Santander Protect bei schweren Krankheiten sichert die versicherte Person finanziell ab, wenn sie an einer der folgenden genannten und in § 4 näher definierten schweren Krankheiten erkrankt:
          </p>
          <ol type="a">
            <li>Invasiver Krebs</li>
            <li>Herzinfarkt</li>
            <li>Schlaganfall</li>
            <li>Nierenversagen</li>
            <li>Hauptorgantransplantation</li>
            <li>Bypass-Operation der Koronararterien</li>
          </ol>

          <p>Im Versicherungsfall zahlen wir am ersten des jeweiligen Monates die im Versicherungsschein genannte monatliche Versicherungssumme bis zu maximal 12 Monate für maximal eine der zuvor genannten schweren Krankheiten. Insgesamt gibt es je versicherter Person während der Vertragslaufzeit einen Anspruch auf bis zu insgesamt maximal 36 Monatszahlungen gemäß § 11 AVB &ndash; Santander Protect.
          </p>

          <p>
            <strong>§2. Wer ist mit diesen Leistungsbaustein versichert?</strong><br>
            Die versicherte Person gemäß dem Versicherungsschein, die beim Eintritt des Versicherungsfalls entweder Beamter, selbstständig tätig oder im Ruhestand (einschl. Vorruhestand) ist.
          </p>
          <p>
            Die erste versicherte Person, wenn sie beim Eintritt des entweder Beamter, selbstständig tätig oder im Ruhestand (einschl. Vorruhestand) ist. Die erste versicherte Person ist nicht mit diesem Leistungsbaustein versichert.
          </p>
          <p>
            Die zweite versicherte Person, wenn sie beim Eintritt des Versicherungsfalls entweder Beamter, selbstständig tätig oder im Ruhestand (einschl. Vorruhestand) ist. Die erste versicherte Person ist nicht mit diesem Leistungsbaustein versichert.
          </p>
          <p>
            <strong>§3. Welchen Zeitraum umfasst die Wartezeit?</strong><br>
            Die Wartezeit beträgt drei Monate ab Beginn des Versicherungsschutzes. Dies bedeutet, sollte innerhalb der ersten drei Monate eine der schweren Krankheiten diagnostiziert werden oder der Verdacht auf eine der schweren Krankheiten bestehen, ist dies nicht versichert. Die versicherte Person hat für den gesamten Zeitraum dieses Ereignisses keinen Versicherungsschutz. Die schwere Krankheit ist erst versichert, wenn der Verdacht durch einen Arzt und die Diagnose von einem Arzt nach der Wartezeit geäußert wurde.
          </p>
          <p>
            Ein Verdacht auf eine der im Versicherungsschutz inbegriffenen schweren Krankheit besteht, wenn ein behandelnder qualifizierter Arzt der versicherten Person, Ihnen oder der versicherten Person einen Verdacht auf eine dieser Krankheiten gegenüber äußert.
          </p>
          <p>
            <strong>§4. Was sind die Leistungsvoraussetzungen des Versicherungsschutzes bei schweren Krankheiten?</strong><br>
            Ihre für den Leistungsanspruch notwendige Diagnose bzw. Bestätigung muss endgültig und eindeutig sein. Gegebenenfalls müssen zusätzlich für die Feststellung des Leistungsanspruchs eine von uns ausdrücklich verlangte Begründung bzw. Test(s) vorgelegt werden. Soweit in den folgenden Definitionen besondere Unterlagen genannt sind, sind diese der Diagnose bzw. Bestätigung beizufügen.
          </p>
          <p>Manche der hier aufgeführten Definitionen enthalten vereinfachte Erläuterungen medizinischer Begriffe. Diese vereinfachten Erläuterungen sind jedoch rechtlich unverbindlich. Der Anspruch auf Leistung hängt allein von der jeweiligen Definition, ggf. unter Berücksichtigung von Ausschlüssen ab:
          </p>
          <ol type="a">
            <li>
              <strong>Invasiver Krebs</strong><br>
              Vereinfachte Erläuterung: Krebs ist eine bösartige Gewebsneubildung, bei der Tumorzellen durch unkontrolliertes Wachstum gesunde Körperzellen im Gewebe verdrängen und zerstören. Invasiver Krebs im Sinne dieser Bedingungen umfasst invasive Krebserkrankungen aber auch Leukämie, maligne Knochenmarkserkrankungen, Lymphome (vereinfachte Erläuterung: bösartige Tumore des Lymphsystems) und Morbus Hodgkin. Die Diagnose muss durch einen Pathologen oder Onkologen anhand eines feinstgeweblichen Nachweises erfolgen.
            </li>
            <li>
              <strong>Herzinfarkt</strong><br>
              Versichert ist das Absterben eines Teils des Herzmuskels infolge unzureichender Blutzufuhr zum Herzmuskel genannt Myokardinfarkt (vereinfachte Erläuterung: Umgangssprachlich auch Herzinfarkt oder Herzanfall genannt). Der Zeitpunkt des Auftretens und die folgenden Anzeichen müssen durch einen Kardiologen nach den Regeln der deutschen Gesellschaft für Kardiologie bzw. den entsprechenden in einem anderen anerkannten Staat angewandten Regeln nachgewiesen werden: Charakteristischer Anstieg der herzspezifischen Enzyme z.B. Troponine sowie mindestens eines der beiden folgenden Kriterien:
              <ul>
                <li>Typisch klinische Symptome (zum Beispiel charakteristische Brustschmerzen) oder</li>
                <li>Neue, charakteristische Veränderungen im Elektrokardiogramm (EKG)</li>
              </ul>
            </li>
            <li>
              <strong>Schlaganfall</strong><br>
              Versichert ist ein Schlaganfall, d.h. eine plötzlich auftretende Schädigung des Gehirns, hervorgerufen durch eine akute Minderdurchblutung oder eine Hirnblutung. Beide führen zu einem Blut- und Sauerstoffmangel mit Absterben von Gehirnzellen und daraus resultierend zu neurologischen Störungen. Die Hirnzellen bleiben größtenteils irreversibel geschädigt. Der Zeitpunkt des Auftretens und die folgenden Anzeichen müssen durch einen qualifizierten Arzt bestätigt werden:
              <ul>
                <li>Permanentes neurologisches Defizit mit anhaltenden klinischen Symptomen oder</li>
                <li>Eindeutiger Nachweis von abgestorbener Gewebeteile oder</li>
                <li>Blutung nachgewiesen auf einem Gehirnscan und ein neurologisches Defizit mit anhaltenden klinischen Symptomen von mindestens 24 Stunden.</li>
              </ul>

            </li>
            <li>
              <strong>Nierenversagen</strong><br>
              Versichert ist ein endgültiges, nicht mehr zu behebendes Nierenversagen. Entweder muss eine regelmäßige Dialyse durchgeführt werden oder eine Nierentransplantation wird gemacht. Die Diagnose muss durch einen Arzt für Nephrologie nachgewiesen werden.
            </li>
            <li>
              Hauptorgantransplantation Die versicherte Person muss eines der folgenden Organe durch ein Transplantat oder ein künstliches Organ vollständig ersetzt bekommen: Herz, Lunge, Leber, Bauchspeicheldrüse oder Knochenmark.
            </li>
            <li>
              <strong>Bypass-Operation der Koronararterien</strong><br>
              Versichert ist die Bypass-Operation der Koronararterien. Vereinfachte Erläuterung: Bei der Bypass-Operation am Herzen werden verengte oder verschlossene Abschnitte der Herzkranzgefäße (Koronararterien) überbrückt mit dem Ziel, die Durchblutung des Herzmuskels wiederherzustellen. Als Überbrückung dienen körpereigene Arterien der Brustwand oder Venen- oder Arterienstücke aus dem Unter- oder Oberschenkel bzw. künstliche Gefäße, die in die Herzkranzarterie eingenäht werden. Dies schließt auch das Anlegen von mehreren Bypässen während derselben Operation ein. Folgende Befunde müssen vorgelegt werden: Myokardisch Myokardischämie und Angina-pectoris-Symptomatik und Vorliegen signifikanter proximaler Stenosen (&gt;70%) eines oder mehrerer der folgenden Koronargefäße:
              <ul>
                <li>Linke Koronararterie (LCA)</li>
                <li>Rechte Koronararterie (RCA)</li>
                <li>Ramus interventricularis anterior (RIVA)</li>
                <li>Ramus circumflexus (RCX)</li>
              </ul>
            </li>
          </ol>

          <p>
            <strong>§5. Welche Einschränkungen und Ausschlüsse der Leistungspflicht gibt es?</strong>
          </p>
          <ol>
            <li>
              Wir leisten nicht, wenn die schwere Krankheit der versicherten Person verursacht, ist:

              <ol type="a">
                <li>durch Alkoholismus oder eine Suchterkrankung (Drogen- oder Medikamentenmissbrauch) oder durch einen Unfall infolge eines Rausches der versicherten Person;</li>
                <li>direkt oder indirekt durch kriegerische Ereignisse oder innere Unruhen, sofern die versicherte Person aktiv teilgenommen hat;</li>
                <li>direkt oder indirekt durch Atom- oder Kernspaltung und / oder -fusion oder andere ähnliche Reaktionen oder radioaktive Kräfte oder Stoffe verursacht wurden.</li>
                <li>direkt oder indirekt durch den vorsätzlichen Einsatz von biologischen oder chemischen Waffen oder dem vorsätzlichen Einsatz oder der vorsätzlichen Freisetzung von biologischen oder chemischen Stoffen, sofern der Einsatz oder das Freisetzen darauf gerichtet sind, das Leben einer Vielzahl von Personen zu gefährden.</li>
                <li>
                  durch Krankheiten und Verletzungen, die durch grobe Fahrlässigkeit der versicherten Person verursacht wurde oder absichtlich von ihr verursacht wurden, es sei denn es handelt sich dabei um:
                  <ul>
                    <li>Folge eines Selbstmordversuches der drei Jahre nach Beginn des Versicherungsschutzes verübt wurde oder</li>
                    <li>Folge eines Selbstmordversuches, der nachweislich durch eine psychische Störung verursacht wurde, die den freien Willen ausschließt und nicht durch Alkohol-, Drogen- oder Medikamentenmissbrauch verursacht wurde oder vorsätzlich mit Zustimmung der versicherten Person verursacht wurden. Nicht beinhaltet in dieser Klausel ist die ärztliche Sterbehilfe, wenn die versicherte Person palliativ betreut wird und an einer unheilbaren Krankheit leidet.</li>
                  </ul>
                </li>
                <li>durch Schwangerschaft, wegen Schwangerschaftsabbruch, Fehlgeburt oder Entbindung;</li>
                <li>durch Begehung oder den Versuch einer Straftat oder illegalen Handlung durch die versicherte Person;</li>
                <li>oder durch Folgen einer medizinisch nicht notwendigen Behandlung.</li>
              </ol>
            </li>
            <li>
              Nicht versichert sind:
              <ol type="a">
                <li>
                  Die folgenden Krebserkrankungen:
                  <ul>
                    <li>Carcinoma in suit, nicht-invasive Krebserkrankungen oder alle prämalignen Erkrankungen</li>
                    <li>Maligne Melanome der Haut nach der TNM- Klassifikation T1a N0 M0, T1b N0 M0 und T2a N0 M0</li>
                    <li>Hyperkeratosen, Dermatofibrosarkonma, Basaliome und Spinaliome</li>
                    <li>Früher Prostatakrebs nach der TNM-Klassifikation T1a N0 M0 und T1b N0 M0, T2a N0 M0</li>
                    <li>Alle anderen Tumorerkrankungen im Stadium I oder Stadium 0 (UICC-Stadien), wenn für die Behandlung weder Strahlen- oder Chemotherapie erforderlich ist. Die medizinische Notwendigkeit einer Strahlen- oder Chemotherapie ist von einem qualifizierten Arzte zu bestätigen.</li>
                  </ul>
                </li>
                <li>Angina Pectoris ohne Myokardinfarkt und andere Koronarsyndrome</li>
                <li>Transitorische ischämische Attacken (TIA) oder das Absterben von Sehnervengewebe für die Netzhaut bzw. den Augenschlag;</li>
              </ol>
            </li>
          </ol>
          <p>
            <strong>§6. Was ist vor und nach Eintritt des Versicherungsfalles zu beachten bzw. zu tun? (Obliegenheiten)?</strong><br>
            Zusätzlich zu den in § 9 AVB-Santander Protect aufgeführten Obliegenheiten gilt Folgendes:
          </p>
          <ol>
            <li>Das Eintreten des Versicherungsfalls ist dem Versicherer unverzüglich anzuzeigen.</li>
            <li>Zum Nachweis der schweren Krankheit müssen Sie uns eine ärztlich attestierte Bescheinigung und auf unser Verlangen ein ärztliches Zeugnis auf unserem Original-Vordruck einreichen. Der behandelnde Arzt muss das uns bestätigen wann ein behandelnder Arzt der versicherten Person, Ihnen oder der versicherten Person den Verdacht erstmals bezüglich der schweren gegenüber äußert hat.</li>
            <li>Bei Verletzung einer der Obliegenheiten nach den vorgenannten Absätzen gilt § 9 Nr. 3 &ndash; 5 AVB-Santander Protect.</li>
          </ol>

          <a class="anchor-link" name="step-10" id="step-10"></a>
          <h2>Datenschutzhinweise</h2>
          <p>(Stand 2021/05)</p>
          <h3>Informationen zur Datenverarbeitung</h3>
          <p>Ein wesentliches Ziel für CNP Santander Insurance Europe DAC (wir) und der zugehörigen Unternehmen der Unternehmensgruppe ist es, die uns anvertrauten Informationen von unseren Kunden und allen, mit denen wir kommunizieren, zu schützen. Dies ist entscheidend für die Aufrechterhaltung unseres Rufs und für die Erfüllung der umfangreichen gesetzlichen und behördlichen Anforderungen, die an uns gestellt werden. Transparenz ist uns im Umgang mit Informationen sehr wichtig und wir verpflichten uns die folgenden Anforderungen zu erfüllen:</p>
          <ul>
            <li>die Datenschutz-Grundverordnung (DSGVO),</li>
            <li>das Bundesdatenschutzgesetz (BDSG),</li>
            <li>die datenbezogenen Bestimmungen des Versicherungsvertragsgesetzes (VVG),</li>
            <li>sowie alle anderen relevanten Gesetze und die Ausübung Ihrer Rechte daraus.</li>
          </ul>
          <p>In diesen Datenschutzhinweisen verwenden wir die in der Datenschutz-Grundverordnung (&bdquo;DSGVO&ldquo;) definierte Terminologie. </p>

          <h3>Verantwortlich für die Datenverarbeitung</h3>
          <p>
            Ihre personenbezogenen Daten (des Versicherungsnehmers bzw. der versicherten Person) werden zunächst von der Santander Consumer Bank AG, Santander Platz 1, 41061, erhoben. Der Verantwortliche, die CNP Santander Insurance Europe DAC, ist in Irland ansässig und ist für die Verarbeitung Ihrer personenbezogenen Daten verantwortlich.
          </p>
          <p>
            Wir können unter folgender Adresse kontaktiert werden:</p>
          <p>
            CNP Santander Insurance Europe DAC<br>2nd Floor Three Park Place<br>Hatch Street Upper<br>Dublin 2<br>Irland.
          </p>
          <p>Sie erreichen unseren Datenschutzbeauftragten per Post unter der oben genannten Anschrift mit dem Zusatz &ndash; z. Hd. Datenschutzbeauftragten - oder per E-Mail an: dataprotectionofficer@cnpsantander.com
          </p>

          <h3>Zweck und Rechtsgrundlage der Verarbeitung personenbezogener Daten</h3>
          <p>Wir verarbeiten personenbezogene Daten wie Ihren Namen, Ihre Kontaktdaten, Angaben zu Ihrem Darlehen bei der Santander Consumer Bank AG oder zu Ihrem Fahrzeug (falls dies für Ihren Versicherungsvertrag relevant ist) zu folgenden Zwecken:</p>
          <ul>
            <li>Wenn Sie den Versicherungsschutz beantragen, benötigen wir für den Abschluss des Versicherungsvertrags die von uns im Antragsformular angeforderten Informationen, um unsere Antragsprüfungen durchzuführen und den Antrag anzunehmen. <strong>Bitte beachten Sie, dass der Abschluss eines Versicherungsvertrages ohne Verarbeitung Ihrer personenbezogenen Daten nicht möglich ist.</strong></li>


            <li>
              Wir benötigen ferner Ihre personenbezogenen Daten für die Erfüllung des Vertragsverhältnisses gemäß Art. 6 Abs. 1b) DSGVO um:
              <ul>
                <li>die Erfüllung Ihres Versicherungsvertrages,</li>
                <li>die Bearbeitung Ihrer Fragen und Forderungen</li>
                <li>und die Bearbeitung von Leistungsanträgen des Versicherungsnehmers zu gewährleisten. Dies beinhaltet die Speicherung personenbezogener Daten.</li>
              </ul>
              Falls wir Ihre Gesundheitsdaten für die Bearbeitung Ihres Leistungsantrags benötigen, bitten wir in diesem Fall um Ihre gesonderte Einwilligung gemäß Art. 9 Abs. 2 a) und Art. 7 DSGVO.</li>

            <li>Zur Erfüllung gesetzlicher Rechtspflichten wie zum Beispiel die Schadens- und Beitragsbuchhaltung, zu Revisionszwecken oder bei Kontrollen zur Geldwäschebekämpfung. Rechtsgrundlage für die Verarbeitung sind in diesem Fall die jeweiligen gesetzlichen Bestimmungen gemäß Art. 6 Abs. 1c) DSGVO.</li>
            <li>Möglicherweise müssen wir personenbezogene Daten, soweit sie zur Wahrung berechtigter Interessen der verarbeitenden Stelle oder Dritter erforderlich sind, verarbeiten (Art. 6 Abs. 1 Buchst. F DSGVO), wenn diese nicht in den Geltungsbereich der Vertragserfüllung oder aufgrund einer gesetzlichen Verpflichtung fallen. Beispielsweise:
              <ul>
                <li>Rücklagenbildung, Beitragsberechnung, versicherungsmathematische Analyse, aus betriebswirtschaftlichen Gründen, Entwicklung von Prozessen und Dienstleistungen (wir verwenden so weit wie möglich anonymisierte Daten);</li>
                <li>Risikomanagement und Betrugsprävention;</li>
                <li>Zur Gewährleistung der IT-Sicherheit und des IT-Betriebs;</li>
                <li>Kundenzufriedenheitsumfragen;</li>
                <li>Makroökonomische Berichte oder für interne und administrative Buchhaltungszwecke;</li>
                <li>Die anonymisierten personenbezogenen Daten, um unsere Datenschutzanforderungen zu erfüllen.</li>
              </ul>
            </li>
          </ul>
          <p>
            Wir verarbeiten Ihre personenbezogenen Daten nur für die oben genannten Zwecke und stellen sicher, dass sie nur Personen zur Verfügung stehen, die ein berechtigtes Interesse haben und daher Zugriff benötigen. Wenn wir Ihre personenbezogenen Daten für einen anderen Zweck verarbeiten möchten, stellen wir sicher, dass sie mit den oben genannten Zwecken im Einklang sind.
          </p>

          <h3>Empfänger der personenbezogenen Daten</h3>
          <p>Die Erfüllung unserer Dienstleistungen erfordert möglicherweise die Weitergabe Ihrer personenbezogenen Daten an die nachfolgenden Empfängerkategorien:</p>
          <p>
            <strong>Unternehmen der Unternehmensgruppe:</strong><br>
            wir nutzen eine Konzerngesellschaft, die Back-Office- und IT-Services anbietet. Unter bestimmten Umständen können wir auch Informationen an unsere Mutterunternehmen weitergeben.
          </p>
          <p>
            <strong>Dienstleister:</strong><br>
            für die Vertragsverwaltung, Leistungs-/Schadenbearbeitung, Beschwerdebearbeitung, Beitragseinzug und für die Kündigungsbearbeitung (sofern diese Tätigkeit nicht von Santander Consumer AG durchgeführt wird).
          </p>
          <p>
            <strong>Fulfillment-Unternehmen:</strong><br>
            wir können externe Fulfillment-Unternehmen nutzen, um das Versenden Ihrer Versicherungsunterlagen innerhalb der vorgeschriebenen gesetzlichen Fristen sicherzustellen.
          </p>
          <p>
            <strong>Vermittler:</strong><br>
            Wir können bestimmte Informationen bezüglich der Vertragsdauer oder der im Rahmen Ihres Vertrages eingereichten Leistungsanfragen an die Santander Consumer Bank AG weitergeben. Dies dient der Wahrung unserer berechtigten Interessen und der Gewährleistung einer transparenten Verteilung der Vermittleranreize.
          </p>
          <p>
            <strong>Rückversicherer:</strong><br>
            Wir können Ihre Versicherungsnummer und begrenzte Informationen an Rückversicherer weitergeben, um unser Risiko für unser Unternehmen im Rahmen unserer berechtigten Interessen abzusichern.
          </p>
          <p>
            <strong>IT-Dienstleister:</strong><br>
            Bereitstellung von IT-System-, IT-Sicherheitsdiensten- und Marktforschungsergebnissen.
          </p>
          <p>
            <strong>Andere Empfänger:</strong><br>
            Wir können Ihre personenbezogenen Daten an andere Empfänger wie Behörden oder Aufsichtsbehörden weitergeben, um den gesetzlichen Meldepflichten nachzukommen. Eine
          </p>
          <p>
            Liste der von uns verwendeten Auftragsnehmern und Dienstleistern finden Sie auf unserer Website unter: <a href="www.cnpsantander.de/p/dienstleisterliste" aria-invalid="true">www.cnpsantander.de/p/dienstleisterliste</a>.
          </p>

          <h3>Aufbewahrung personenbezogener Daten</h3>
          <p>Der Zeitraum, für den wir Informationen speichern, hängt von der Verwendung dieser Informationen ab. In einigen Fällen bestehen rechtliche Anforderungen, Daten für einen Mindestzeitraum zu speichern. Wir speichern Informationen nicht länger als für die Zwecke erforderlich, für die die Daten erhoben und verarbeitet wurden, es sei denn, gesetzliche Vorschriften schreiben etwas anderes vor. Ihre personenbezogenen Daten werden zehn Jahre nach Kündigung / Ablauf Ihres Versicherungsvertrags sicher anonymisiert, es sei denn, wir sind verpflichtet diese für einen anderen berechtigten Zweck aufzubewahren.</p>

          <h3>Datenübermittlung an ein Drittland</h3>
          <p>Die oben beschriebene Weitergabe von personenbezogenen Daten an Dritte kann zur Datenübermittlung an ein Drittland führen, das kein angemessenes Schutzniveau für den Datenschutz bietet. In diesem Fall hat der Versicherer entsprechende Maßnahmen getroffen, um den Datenschutz und die Einhaltung der geltenden Gesetze zu gewährleisten. Detaillierte Informationen zu den Datenübermittlungen sowie zu den entsprechenden Datenschutzmaßnahmen erhalten Sie von unserem Datenschutzbeauftragten.</p>

          <h3>Automatisierte Entscheidungen und Profiling</h3>
          <p>Ihre Berechtigung zum Abschluss eines Versicherungsvertrags mit uns basiert auf versicherungsmathematisch festgelegten Annahmerichtlinien und Berechnungsgrundlagen. Wenn Sie beispielsweise einen Versicherungsantrag einreichen, wird Ihr Alter und Beschäftigungsstatus entsprechend geprüft. Diese Entscheidung ist erforderlich, um einen Vertrag zwischen Ihnen und uns, dem Versicherer, gemäß Art. 22 Abs. 2 a) DSGVO abzuschließen. Sind Sie nicht mit der Entscheidung zufrieden, können Sie ein Eingreifen einer Person beantragen, indem Sie sich an unseren Datenschutzbeauftragten wenden.</p>

          <h3>Anfragen und weitere Rechte</h3>
          <p>Sie haben gemäß der DSGVO besondere Rechte in Bezug auf von uns verarbeitete personenbezogene Daten, z. B. das Recht auf Auskunft zu Ihren personenbezogenen Daten, diese zu korrigieren, zu löschen oder (unter bestimmten Umständen) einzuschränken und zu übertragen. Wenn wir Ihre Daten zur Wahrung berechtigter Interessen verarbeiten, können Sie der Datenverarbeitung widersprechen, wenn Ihre Situation zu unangemessenen Auswirkungen auf Ihre Datenschutzrechte führt. Wenn Sie Fragen, Anmerkungen oder Beschwerden bezüglich der Erhebung oder Verwendung Ihrer personenbezogenen Daten oder bezüglich dieser Datenschutzhinweise haben, wenden Sie sich bitte an unseren Datenschutzbeauftragten.</p>
          <p>Wenn Sie der Meinung sind, dass wir die Datenschutzbestimmungen nicht einhalten, können Sie eine Beschwerde unbeschadet anderweitiger Rechtsbehelfe bei unserem Datenschutzbeauftragten oder alternativ bei der zuständigen Datenschutzaufsichtsbehörde einreichen.</p>

        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.avb-wrapper {
  .stepper-content {
    padding-right: 4rem;
  }
  p, ul, ol, dl {
    max-width: inherit;
  }
}
</style>

<script>
export default {
  name: "AVB",
};
</script>
