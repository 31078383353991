<template>
  <div  class="header-tab-container current padded">
    <h2>Der richtige Kontakt – im Schadenfall</h2>
    <div class="row">
      <div class="col-6">
        <h3>Für unsere Kunden</h3>
        <p><strong>Kundenservice Santander Protect / Santander RSV Plus</strong><br>
          CNP Santander Insurance Europe Dac<br>
          Postfach 32 10 80<br>
          40425 Düsseldorf
        </p>
        <p>
          0800 5888 – 523 (kostenlos)<br>
          Montag bis Freitag 9.00 – 18.00 Uhr<br>
          <a href="mailto:kundenservice@ger.cnpsantander.com">kundenservice@ger.cnpsantander.com</a>
        </p>
        <p class="font-size-small">
          <strong>Anbieterkennzeichnung</strong><br>
          CNP Santander Insurance Europe Dac<br>
          CNP Santander Insurance Life Dac<br>
          2nd Floor, Three Park Place<br>
          Hatch Street Upper<br>
          Dublin 2<br>
          D02 FX65<br>
          Ireland
        </p>
        <p class="font-size-small">
          CNP Santander Insurance Europe Dac hat folgende Registrierungsnr.: 488062.<br>CNP Santander Insurance Life Dac hat folgende Registrierungsnr.: 488063.
        </p>
      </div>
      <div class="col-6">
        <e-claims gap="3"></e-claims>
      </div>
    </div>
  </div>
</template>

<script>
import eClaims from "../../components/eClaims";

export default {
  name: "Kontakt",
  components: {
    eClaims
  }
};
</script>
