import Vue from "vue";
import VueRouter from "vue-router";
import Overview from "./../views/Overview";
import Produkte from "./../views/pages/Produkte";
import Beratungshilfe from "./../views/pages/Beratungshilfe";
import VorsorgeRechner from "./../views/pages/VorsorgeRechner";
import Schadensmeldung from "./../views/pages/Schadensmeldung";
import AVB from "./../views/pages/AVB";
import FAQ from "./../views/pages/FAQ";
import Kontakt from "./../views/pages/Kontakt";

Vue.use(VueRouter);

const routes = [
  {
    name: "Overview",
    path: "/",
    component: Overview,
  },
  {
    name: "Produkte",
    path: "/nav1/Produkte",
    component: Produkte,
  },
  {
    name: "Beratungshilfe",
    path: "/nav2/Beratungshilfe",
    component: Beratungshilfe,
  },
  {
    name: "VorsorgeRechner",
    path: "/nav2/VorsorgeRechner",
    component: VorsorgeRechner,
  },
  {
    name: "Schadensmeldung",
    path: "/nav3/Schadensmeldung",
    component: Schadensmeldung,
  },
  {
    name: "AVB",
    path: "/nav3/AVB",
    component: AVB,
  },
  {
    name: "FAQ",
    path: "/nav3/FAQ",
    component: FAQ,
  },
  {
    name: "Kontakt",
    path: "/nav3/Kontakt",
    component: Kontakt,
  },
];

const router = new VueRouter({
  linkExactActiveClass: "-is-active",
  routes,
});

export default router;
