<template>
  <div class="footer-nav-wrapper">
    <div class="grid-split -split-3">
      <div>
        <ul class="nav-list" :class="{
          'active':
            isRoute('Produkte')
          }">
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'Produkte' }">
            <a class="tab-link" @click="reloadPage"><span>Produkte</span></a>
          </router-link>
        </ul>
      </div>
      <div>
        <ul class="nav-list" :class="{
          'active':
             isRoute('Beratungshilfe') ||
             isRoute('VorsorgeRechner')
          }">
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'Beratungshilfe' }">
            <a class="tab-link" @click="reloadPage"><span>Beratungshilfe</span></a>
          </router-link>
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'VorsorgeRechner' }">
            <a class="tab-link" @click="reloadPage"><span>Einkommenslücken-Rechner</span></a>
          </router-link>
        </ul>
      </div>
      <div>
        <ul class="nav-list" :class="{
          'active':
             isRoute('Schadensmeldung') ||
             isRoute('FAQ') ||
             isRoute('AVB') ||
             isRoute('Kontakt')
          }">
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'Schadensmeldung' }">
            <a class="tab-link" @click="reloadPage"><span>Schadenmeldung</span></a>
          </router-link>
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'FAQ' }">
            <a class="tab-link" @click="reloadPage"><span>FAQ</span></a>
          </router-link>
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'AVB' }">
            <a class="tab-link" @click="reloadPage"><span>AVB</span></a>
          </router-link>
          <router-link tag="li" class="nav-list-elem" active-class="active" exact :to="{ name: 'Kontakt' }">
            <a class="tab-link" @click="reloadPage"><span>Kontakt</span></a>
          </router-link>
        </ul>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: "FooterNav",
  methods: {
    reloadPage(e) {
      var headerTabStepperContainer = document.querySelectorAll('.header-tab-container');
      var headerTabStepper = document.querySelectorAll('.header-tabs .tab');
      if (headerTabStepperContainer) {
        for (let k = 0; k < headerTabStepperContainer.length; k++) {
          headerTabStepperContainer[k].classList.remove('current');
        }
        for (let l = 0; l < headerTabStepper.length; l++) {
          headerTabStepper[l].classList.remove('current');
        }
        if (headerTabStepperContainer[0]) {
          headerTabStepperContainer[0].classList.add('current');
        }
        if (e.target.innerHTML === 'Schadenmeldung') {
            const allStepperContent = document.querySelectorAll('.stepper-content-inner');
            const allStepperNavItems = document.querySelectorAll('.stepper-nav-list > li');
            for (let o = 0; o < allStepperContent.length; o++) {
                allStepperContent[o].classList.remove('current');
            }
            for (let m = 0; m < allStepperNavItems.length; m++) {
                allStepperNavItems[m].classList.remove('current');
            }
            document.querySelector('.stepper-content-inner[data-tab="0"]').classList.add('current');
        }
      }
    },
    isRoute(text) {
      return this.$route.matched.some(({ name }) => name === text)
    }
  }
};
</script>
