<template>
  <div class="set-height-100">
    <div class="stepper-container">
      <div class="stepper-nav" style="background: #9bc3d3 !important;">
        <ul class="stepper-nav-list -default" data-name="">
          <li data-tab="0" class="current">Allgemein</li>
          <li data-tab="1">Elternzeit</li>
          <li data-tab="2">Kurzzeitpflege</li>
          <li data-tab="3">Kurzarbeit</li>
          <li data-tab="4">Arbeitslosigkeit</li>
          <li data-tab="5">Schwere Krankheiten</li>
          <li data-tab="6">Arbeitsunfähigkeit<small>(Inklusive psychischer Erkrankung)</small></li>
        </ul>
      </div>
      <div class="stepper-content current">
        <h1>FAQ</h1>
        <div class="stepper-content-inner current" data-tab="0">
          <h2>Santander Protect allgemein</h2>
          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Personen</template>
              <template slot="accordion-content">
                <accordion>
                  <accordion-item>
                    <template slot="accordion-trigger">Wer kann Santander Protect abschließen?</template>
                    <template slot="accordion-content">
                      <p>Eine natürliche Person im Alter von 18 bis einschließlich 64 Jahren, solange sie nicht voll erwerbsgemindert und nicht vollständig im Ruhestand ist. Die versicherte Person muss ihren Wohnsitz und dauernden Aufenthalt in der Bundesrepublik Deutschland haben. </p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Wie viele Personen können über einen Vertrag versichert werden?</template>
                    <template slot="accordion-content">
                      <p>Es können bis zu zwei Personen pro Vertrag abgesichert werden. </p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Ist ein Abschluss auch dann möglich, wenn bei der VP bereits ein Leistungsfall besteht?</template>
                    <template slot="accordion-content">
                      <p>Ein Abschluss ist grundsätzlich möglich. Jedoch besteht für einen bereits eingetretenen Leistungsfall für den dann abgeschlossenen Vertrag kein Versicherungsschutz. Zukünftige Leistungsfälle sind jedoch im Rahmen der Allgemeinen und Besonderen Bedingungen abgesichert. </p>
                    </template>
                  </accordion-item>
                </accordion>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Zeiten</template>
              <template slot="accordion-content">
                <accordion>
                  <accordion-item>
                    <template slot="accordion-trigger">Wie lang ist die Vertragslaufzeit?</template>
                    <template slot="accordion-content">
                      <p>Die Vertragslaufzeit kann frei gewählt werden.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Was bedeutet Wartezeit?</template>
                    <template slot="accordion-content">
                      <p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz besteht und für den damit kein Leistungsanspruch geltend gemacht werden kann. Die Wartezeit beginnt ab dem Versicherungsbeginn. Die Dauer der Wartezeit kann variieren. Die entsprechenden Wartezeiten sind in den dem Vertrag zugrunde liegenden Besonderen Versicherungsbedingungen aufgeführt.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Was bedeutet Karenzzeit?</template>
                    <template slot="accordion-content">
                      <p>Die Karenzzeit definiert die leistungsfreie Zeit ab Eintritt eines Versicherungsfalls. Die Dauer der Karenzzeit kann variieren. Die entsprechenden Karenzzeiten sind in den dem Vertrag zugrunde liegenden Besonderen Versicherungsbedingungen aufgeführt.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Wie lange leistet die Santander Protect bei den einzelnen Versicherungsfällen (ALO, AU, KA, EZ, KZP)?</template>
                    <template slot="accordion-content">
                      <p class="margin">
                        Nach einem anerkannten Leistungsanspruch wird eine Leistung gewährt, solange die Leistungsvoraussetzungen nach wie vor erfüllt sind, maximal jedoch:
                      </p>
                      <ul style="padding-bottom: 0">
                        <li>12 Monate bei Arbeitsunfähigkeit</li>
                        <li>12 Monate bei Arbeitslosigkeit oder schwerer Erkrankung</li>
                        <li>12 Monate bei Kurzarbeit</li>
                        <li>6 Monate bei Elternzeit</li>
                        <li>6 Monate bei Kurzzeitpflege</li>
                      </ul>
                      <p>Insgesamt sind alle monatlichen Leistungen je versicherte Person und Vertrag jedoch auf maximal 36 Monate begrenzt.</p>
                    </template>
                  </accordion-item>
                </accordion>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistungen</template>
              <template slot="accordion-content">
                <accordion>
                  <accordion-item>
                    <template slot="accordion-trigger">Wie hoch ist die maximale Leistung bei den einzelnen Versicherungsfällen (ALO, AU, KA, EZ, KZP)?</template>
                    <template slot="accordion-content">
                      <p>In einem anerkannten Leistungsfall leisten wir die jeweils vertraglich vereinbarte monatliche Versicherungssumme. Die monatlichen Versicherungssummen sind im Versicherungsschein aufgeführt.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Wieso erhält der Kunde eine höhere Leistung bei ALO als bei AU?</template>
                    <template slot="accordion-content">
                      <p>Der gesetzliche Anspruch auf Lohnersatzleistungen bei einer vorübergehenden Arbeitsunfähigkeit, also das Krankengeld, ist höher als der gesetzliche Anspruch auf Lohnersatzleistungen wegen Arbeitslosigkeit. Da die Santander Protect vor allem den möglichen Einkommensverlust im optimalen Preis-Leistungs-Verhältnis absichert, sind die Leistungen für eine unfreiwillige Arbeitslosigkeit auch höher als für eine Arbeitsunfähigkeit.</p>
                    </template>
                  </accordion-item>
                </accordion>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Tarif/Kombinationen</template>
              <template slot="accordion-content">
                <accordion>
                  <accordion-item>
                    <template slot="accordion-trigger">Können einzelne Risiken abgewählt werden?</template>
                    <template slot="accordion-content">
                      <p>Nein, es können keine einzelnen Risiken abgewählt werden. Allerdings können verschiedene Pakete mit unterschiedlichen Risikobausteinen ausgewählt werden.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Kann der Kunde auch nur einzelne Leistungen abschließen (Elternzeit, aber keine Kurzzeitpflege)?</template>
                    <template slot="accordion-content">
                      <p>Nein, nur einzelne Leistungen können nicht abgeschlossen werden.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Erhält der Kunde einen Preisvorteil, wenn er Premium abschließt?</template>
                    <template slot="accordion-content">
                      <p>Nein. Allerdings wurde Santander Protect Premium als Paket konzipiert, um die Absicherung der Risiken in einem optimalen Preis-Leistungs-Verhältnis anbieten zu können. </p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Was bedeutet es, dass die Santander Protect unabhängig von Höhe und Laufzeit des Kredites ist?</template>
                    <template slot="accordion-content">
                      <p>Die Santander Protect ist ein Lifestyleprodukt, das exklusiv nur von Kunden der Santander Bank abgeschlossen werden kann. Flexibilität hinsichtlich einer auf den individuellen Bedarf des Kunden abgestellten wichtigen Absicherung von möglichen Einkommensverlusten nach Höhe wie auch Dauer der Absicherung im Rahmen der hier angebotenen Versicherungsleistungen bedingt eine vollkommen unabhängige Bedarfsanalyse seitens der Kundensituation. Im Leistungsfall erfolgt eine Auszahlung auch darum direkt an den Kunden, damit dieser seinem dann tatsächlichen persönlichen Bedürfnis entsprechend frei die finanziellen Mittel einsetzen kann.</p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Wieso ist die Wartezeit bei ALO kürzer als bei Elternzeit und bei Kurzzeitpflege?</template>
                    <template slot="accordion-content">
                      <p>Die Wartezeit für Elternzeit oder Kurzzeitpflege ist länger, da hier das Risiko größer ist, dass eine versicherte Person bei Vertragsabschluss bereits von einer Schwangerschaft oder einer anstehenden Notwendigkeit einer Pflege eines nahen Angehörigen weiß. Um ein gutes Preis-Leistungs-Verhätnis bieten zu können, sind demnach die Wartezeiten für die Absicherung der Elternzeit und der Kurzzeitpflege entsprechend höher. </p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Können mehrere Versicherungsleistungen unterschiedlicher Tarife gleichzeitig in Anspruch genommen werden?</template>
                    <template slot="accordion-content">
                      <p>Per versicherte Person kann immer nur eine Leistung gleichzeitig in Anspruch genommen werden. </p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Kann der Kunde die Versicherung nach Vertragsende beliebig oft wieder abschließen?</template>
                    <template slot="accordion-content">
                      <p>Ja, solange die versicherte Person die Annahmekriterien nach wie vor erfüllt. Eine Absicherung sollte sich an der tatsächlichen Einkommenslücke orientieren.  </p>
                    </template>
                  </accordion-item>
                  <accordion-item>
                    <template slot="accordion-trigger">Kann der Kunde einen einzelnen Tarif „nachbuchen“, wenn die maximale Anzahl an Versicherungszahlungen ausgeschöpft ist?</template>
                    <template slot="accordion-content">
                      <p>Nein, das ist nicht möglich.</p>
                    </template>
                  </accordion-item>
                </accordion>
              </template>
            </accordion-item>
          </accordion>
        </div>
        <div class="stepper-content-inner" data-tab="1">
          <h2>Elternzeit</h2>
          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Was ist, wenn die versicherte Person bereits zum Abschluss schwanger ist? </template>
              <template slot="accordion-content">
                <p>Voraussetzung für die Leistungszahlung ist, dass das Kind erst nach der Wartezeit von 9 Monaten zur Welt kommt. </p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Leistungsvoraussetzungen gelten für die Zahlung der Leistung hinsichtlich Elternzeit?</template>
              <template slot="accordion-content">
                <p class="margin">Für einen Leistungsanspruch müssen die folgenden Bedingungen erfüllt sein:</p>
                <ul style="padding-bottom: 0">
                  <li>Die versicherte Person muss bei Eintritt des Leistungsfalls einer sozialversicherungspflichtigen Beschäftigung nachgehen.</li>
                  <li>Das Kind der versicherten Person ist nach Ablauf der Wartezeit geboren oder adoptiert worden.</li>
                  <li>Die versicherte Person muss einer sozialversicherungspflichtigen Beschäftigung als Arbeitnehmer nachgehen. </li>
                  <li>Elternzeit muss in Anspruch genommen werden.</li>
                  <li>Die versicherte Person bezieht Elterngeld nach dem Bundeselterngeld- und Elternzeitgesetz.</li>
                  <li>Die Arbeitszeit der versicherten Person muss aufgrund der Elternzeit verkürzt sein.</li>
                  <li>Die versicherte Person geht keiner Beschäftigung von mehr als 30 Stunden pro Woche nach.</li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Unterlagen müssen im Falle der Elternzeit eingereicht werden?</template>
              <template slot="accordion-content">
                <p class="margin">Bei Inanspruchnahme wegen Elternzeit sind folgende Unterlagen einzureichen:</p>
                <ul style="padding-bottom: 0">
                  <li>Benachrichtigung und regelmäßiger Nachweis über den Bezug des Elterngeldes </li>
                  <li>Eine Kopie der Geburtsurkunde oder des Adoptionsnachweises</li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistet der Versicherer auch, wenn die versicherte Person erneut in die Elternzeit möchte?</template>
              <template slot="accordion-content">
                <p>Solange die Leistungsvoraussetzungen erfüllt sind, besteht Versicherungsschutz auch für eine erneute Elternzeit.</p>
                <p>Insgesamt sind alle monatlichen Leistungen je versicherte Person und Vertrag jedoch auf maximal 36 Monate begrenzt.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Macht es einen Unterschied, wenn das Kind mit einer Behinderung zur Welt kommt?</template>
              <template slot="accordion-content">
                <p>Nein.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Gibt es Unterschiede für Alleinerziehende?</template>
              <template slot="accordion-content">
                <p>Nein.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Was passiert, wenn die versicherte Person die Elternzeit frühzeitig abbricht?</template>
              <template slot="accordion-content">
                <p>Der Leistungsanspruch ist abhängig vom Bezug des Elterngeldes nach dem Bundeselterngeld- und Elternzeitgesetz. Wird die Elternzeit beendet oder vorzeitig abgebrochen, endet auch der Leistungsanspruch. </p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Ist für die Leistung wichtig, ob die Kunden verheiratet sind?</template>
              <template slot="accordion-content">
                <p>Nein.</p>
              </template>
            </accordion-item>
          </accordion>
        </div>
        <div class="stepper-content-inner" data-tab="2">
          <h2>Kurzzeitpflege</h2>
          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Was heißt Kurzzeitpflege?</template>
              <template slot="accordion-content">
                <p>Wenn nahe Angehörige länger pflegebedürftig sind, haben Beschäftigte in einem Unternehmen mit mehr als 25 Mitarbeitern einen Anspruch darauf, die Wochenarbeitszeit bis zu<br>
                  24 Monate lang auf bis zu 15 Stunden zu reduzieren, um deren nahe Angehörigen in häuslicher Umgebung zu versorgen. Eine teilweise Freistellung bis zu einer Höchstdauer von<br>
                  24 Monaten ist ebenfalls möglich, wenn Beschäftigte minderjährige pflegebedürftige nahe Angehörige sowohl zu Hause als auch außerhäuslich betreuen (z. B. bei längerem Aufenthalt in einer Spezialklinik). Die sogenannte Familienpflegezeit (hier Kurzzeitpflege) muss spätestens 8 Wochen vor der Inanspruchnahme schriftlich beim Träger angekündigt werden.
                </p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Leistungsvoraussetzungen gelten für die Zahlung der Leistung hinsichtlich Pflege eines nahen Verwandten?</template>
              <template slot="accordion-content">
                <p class="margin">Für einen Leistungsanspruch müssen die folgenden Bedingungen erfüllt sein:</p>
                <ul style="padding-bottom: 0;">
                  <li>Die versicherte Person muss bei Eintritt des Leistungsfalls einer sozialversicherungspflichtigen Beschäftigung nachgehen</li>
                  <li>Nachweis über eine staatlich geförderte Aufstockung des Arbeitsentgelts während der Pflegezeit</li>
                  <li>Die Arbeitszeit muss aufgrund der häuslichen Pflege oder der Umstrukturierung der Pflege eines nahen Verwandten reduziert sein.</li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Wer sind „nahe Angehörige“?</template>
              <template slot="accordion-content">
                <p>Als nahe Angehörige gelten Ehepartner, Kinder, Eltern, Geschwister, Stiefeltern, Schwäger, Schwägerinnen, gleichgeschlechtliche Lebenspartner, mit denen die versicherte Person schon mindestens 1 Jahr zusammenwohnt. </p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Unterlagen müssen im Falle der Pflege eines nahen Verwandten eingereicht werden?</template>
              <template slot="accordion-content">
                <p>Bestätigung  des  Arbeitgebers  mit  Beginn- und  Enddatum  der  Pflegezeit  bzw. Familienpflegezeit sowie regelmäßige Nachweise über das Fortbestehen der Pflegezeit</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistet der Versicherer auch, wenn die versicherte Person erneut in die Pflegezeit eines nahen Verwandten möchte?</template>
              <template slot="accordion-content">
                <p class="margin">Solange die Leistungsvoraussetzungen erfüllt sind, besteht Versicherungsschutz auch für eine erneute Pflegezeit. </p>
                <p>Insgesamt sind alle monatlichen Leistungen je versicherte Person und Vertrag jedoch auf maximal 36 Monate begrenzt.</p>
              </template>
            </accordion-item>
          </accordion>
        </div>
        <div class="stepper-content-inner" data-tab="3">
          <h2>Kurzarbeit</h2>

          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Was bedeutet Kurzarbeit?</template>
              <template slot="accordion-content">
                <p>Kurzarbeit bedeutet, dass alle oder nur ein Teil der Beschäftigten in einem Betrieb weniger Stunden arbeiten, als sie normalerweise arbeiten müssten. Es kann auch sein, dass in der Kurzarbeit die betroffenen Beschäftigten gar nicht arbeiten.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Voraussetzungen gelten für die Zahlung der Leistung hinsichtlich Kurzarbeit?</template>
              <template slot="accordion-content">
                <p>Bedingungsgemäß liegt eine  versicherte Kurzarbeit vor, wenn eine betriebsbedingte konjunkturelle Kurzarbeit seitens des Arbeitgebers verlangt wurde.  Die versicherte Person muss nach § 95 SGB III Anspruch auf Kurzarbeitergeld haben.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Greift die Versicherung auch dann, wenn der Kunde bei Versicherungsabschluss bereits in Kurzarbeit ist und nach 3 Monaten weiterhin in Kurzarbeit ist?</template>
              <template slot="accordion-content">
                <p>Nein, in diesem Fall besteht für die Kurzarbeit kein Leistungsanspruch.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Unterlagen müssen im Falle der Kurzarbeit eingereicht werden?</template>
              <template slot="accordion-content">
                <ul style="padding-bottom: 0;">
                  <li>Eine Bestätigung des Arbeitgebers mit Start- und Enddatum der Kurzarbeit, aus welcher der Grund der Kurzarbeit hervorgeht</li>
                  <li>Eine Bestätigung des Jobcenters über die Anerkennung der Kurzarbeit nach §  95  SGB  III  mit Start- und Enddatum</li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistet der Versicherer auch, wenn die versicherte Person erneut in die Kurzarbeit gerät?</template>
              <template slot="accordion-content">
                <p class="margin">Solange die Leistungsvoraussetzungen erfüllt sind, besteht Versicherungsschutz auch für eine erneute Kurzarbeit. </p>
                <p>Insgesamt sind alle monatlichen Leistungen je versicherte Person und Vertrag jedoch auf maximal 36 Monate begrenzt.  </p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Kann die ausgezahlte Versicherungsleistung höher als das Gehalt des Kunden sein?</template>
              <template slot="accordion-content">
                <p>Die Versicherungsleistung richtet sich nach der Höhe der abgesicherten monatlichen Summe und ist unabhängig von dem tatsächlichen Einkommensverlust durch die Kurzarbeit.</p>
              </template>
            </accordion-item>
          </accordion>
        </div>
        <div class="stepper-content-inner" data-tab="4">
          <h2>Arbeitslosigkeit</h2>
          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Welche Leistungsvoraussetzungen gelten für die Zahlung der Leistung hinsichtlich ALO?</template>
              <template slot="accordion-content">
                <p class="margin">Bedingungsgemäß liegt eine  versicherte Arbeitslosigkeit vor,  wenn die versicherte  Person aus einer sozialversicherungspflichtigen Beschäftigung heraus während der Dauer der Versicherung unverschuldet arbeitslos wird, keiner sozialversicherungspflichtigen Tätigkeit nachgeht, bei der zuständigen Agentur für Arbeit als arbeitslos  gemeldet ist, Arbeitslosengeld wegen Arbeitslosigkeit bezieht und sich aktiv um Arbeit bemüht. Letzteres setzt voraus, dass die versicherte  Person den Anforderungen der Agentur für Arbeit bzgl. der Berechtigung  des Bezuges von Arbeitslosengeld nachkommt.</p>
                <p>Versicherungsschutz wird nur gewährt, wenn die versicherte  Person bis zum Eintritt der Arbeitslosigkeit mindestens seit 6 Monaten fortdauernd bei demselben Arbeitgeber als Arbeitnehmer beschäftigt (angestellt) war.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Unterlagen müssen für die Leistung der ALO eingereicht werden?</template>
              <template slot="accordion-content">
                <ul style="padding-bottom: 0;">
                  <li>Ein mit Kündigungsgrund versehenes Kündigungsschreiben oder eine Bescheinigung des Arbeitgebers über den Kündigungsgrund</li>
                  <li>Eine vom letzten Arbeitgeber für die Agentur für Arbeit ausgefüllte Arbeitsbescheinigungskopie</li>
                  <li>Eine Bescheinigung  der  Agentur  für  Arbeit,  aus  der  sich  ergibt,  seit  wann die versicherte  Person bei  der  Agentur  für  Arbeit  ununterbrochen  als  arbeitslos  und  als arbeitssuchend gemeldet ist</li>
                  <li>Unverzügliches Vorlegen des ersten und jedes weiteren Bewilligungs- oder Änderungsbescheides der Agentur für Arbeit, aus dem sich die Dauer des Bezuges von Arbeitslosengeld ergibt</li>
                  <li>Monatlicher Nachweis über das ununterbrochene Fortbestehen der unverschuldeten Arbeitslosigkeit, den regelmäßigen Bezug von Arbeitslosengeld und die aktiven Bemühungen um eine neue Arbeitsstelle</li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistet der Versicherer auch bei einem befristeten Arbeitsverhältnis?</template>
              <template slot="accordion-content">
                <p>Bei einem befristeten Arbeitsverhältnis leistet die Santander Protect nur, wenn der Arbeitgeber der versicherten Person während der Befristung kündigt. In diesem Fall besteht Anspruch auf Leistung bis maximal zum ursprünglichen Ende des befristeten Arbeitsvertrages. Beim Auslaufen einer Befristung besteht kein Leistungsanspruch.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistet der Versicherer auch, wenn die versicherte Person erneut arbeitslos wird?</template>
              <template slot="accordion-content">
                <p class="margin">
                  Ja, mehrfache Arbeitslosigkeit ist versichert. Bei einer erneuten  Arbeitslosigkeit müssen die Anspruchsvoraussetzungen erfüllt sein, insbesondere muss die versicherte Person auch bei Beginn eines  erneuten Versicherungsfalls mindestens 6 Monate durchgehend  bei demselben (gegebenenfalls neuen) Arbeitgeber vollzeitbeschäftigt gewesen sein (mindestens<br>
                  15 Stunden pro Woche).
                </p>
                <p>Insgesamt sind alle monatlichen Leistungen je versicherte Person und Vertrag jedoch auf maximal 36 Monate begrenzt.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Was ist, wenn die versicherte Person in der Karenzzeit wieder eingestellt werde?</template>
              <template slot="accordion-content">
                <p>In diesem Fall kommt es zu keiner Leistungszahlung. </p>
              </template>
            </accordion-item>
          </accordion>
        </div>
        <div class="stepper-content-inner" data-tab="5">
          <h2>Schwere Krankheiten</h2>
          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Was bedeutet „schwere Krankheiten“ bzw. welche sind versichert?</template>
              <template slot="accordion-content">
                <p class="margin">Bedingungsgemäß sind folgende Krankheiten abgesichert: </p>
                <ul style="padding-bottom: 0">
                  <li>Invasiver Krebs</li>
                  <li>Herzinfarkt</li>
                  <li>Schlaganfall</li>
                  <li>Nierenversagen</li>
                  <li>Hauptorgantransplantation</li>
                  <li>Bypassoperation der Koronararterien</li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Voraussetzungen gelten für die Zahlung der Leistung hinsichtlich schwerer Krankheiten?</template>
              <template slot="accordion-content">
                <p class="margin">Die Absicherung für schwere Krankheiten gilt nur für versicherte Personen, die bei Eintritt des Leistungsfalls keiner sozialversicherungspflichtigen Beschäftigung nachgehen.</p>
                <p class="margin">Trifft dies auf die versicherte Person zu, gilt: </p>
                <p class="margin">Die für den Leistungsanspruch notwendige Diagnose bzw. Bestätigung  muss  endgültig und eindeutig  sein. Gegebenenfalls müssen zusätzlich für die Feststellung des Leistungsanspruchs eine von uns  ausdrücklich verlangte Begründung bzw. Test(s) vorgelegt werden. </p>
                <p class="margin">Soweit bedingungsgemäß für die abgesicherte schwere Krankheit besondere Unterlagen gefordert sind, sind diese der Diagnose bzw. Bestätigung beizufügen. Dies können u. a. folgende Dokumente sein: </p>
                <ul style="padding-bottom: 0">
                  <li>Invasiver Krebs – Die  Diagnose muss durch einen Pathologen oder Onkologen anhand eines feingeweblichen Nachweises erfolgen.</li>
                  <li>
                    <p class="margin">Herzinfarkt – Der Zeitpunkt des Auftretens und die folgenden Anzeichen müssen durch einen Kardiologen nachgewiesen werden: charakteristischer Anstieg der herzspezifischen Enzyme, z. B. Troponine, sowie mindestens eines der beiden folgenden Kriterien:</p>
                    <ul style="padding-bottom: 0">
                      <li>Typisch klinische Symptome (z. B. charakteristische Brustschmerzen)</li>
                      <li>Neue, charakteristischer Veränderungen im Elektrokardiogramm (EKG)</li>
                    </ul>
                  </li>
                  <li>
                    <p class="margin">Schlaganfall – Der Zeitpunkt des Auftretens und die folgenden Anzeichen müssen durch einen qualifizierten Arzt bestätigt werden:</p>
                    <ul style="padding-bottom: 0">
                      <li>Permanentes neurologisches Defizit mit anhaltenden klinischen Symptomen oder</li>
                      <li>Eindeutiger Nachweis von abgestorbenen Gewebeteile oder</li>
                      <li>Blutung  nachgewiesen  auf  einem Gehirnscan  und  ein  neurologisches  Defizit  mit anhaltenden klinischen Symptomen von mindestens 24 Stunden</li>
                    </ul>
                  </li>
                  <li>Nierenversagen – Die Diagnose muss durch einen Arzt für Nephrologie nachgewiesen werden.</li>
                  <li>
                    <p class="margin">Bypassoperation der Koronararterien – Folgende Befunde müssen vorgelegt werden:</p>
                    <p class="margin">Myokardischämie und Angina-pectoris-Symptomatik sowie Vorliegen signifikanter proximaler Stenosen (>70 %) eines oder mehrerer der folgenden Koronargefäße:</p>
                    <ul style="padding-bottom: 0">
                      <li>Linke Koronararterie (LCA) • Rechte Koronararterie (RCA)</li>
                      <li>Ramus interventricularis anterior (RIVA)</li>
                      <li>Ramus circumflexus (RCX)</li>
                    </ul>
                  </li>
                </ul>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Werden Gesundheitsfragen zu den schweren Krankheiten oder zu Vorerkrankungen gestellt?</template>
              <template slot="accordion-content">
                <p>Zum Abschluss werden keine Gesundheitsfragen gestellt. Besteht bereits bei Abschluss die Diagnose oder hat der Arzt bereits einen Verdacht geäußert, ist diese schwere Krankheit jedoch nicht versichert. Das gilt auch, wenn die schwere Krankheit während der Wartezeit diagnostiziert wird oder der Arzt einen Verdacht äußert. </p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Leistet der Versicherer auch, wenn die versicherte Person an einer weiteren schweren Erkrankung erkrankt?</template>
              <template slot="accordion-content">
                <p>Sollte die versicherte Person während der Laufzeit erneut an einer schweren Krankheit erkranken, gilt diese als abgesichert, sofern die Leistungsvoraussetzungen zutreffen. Sollte die Person während des Leistungsbezugs an einer weiteren schweren Krankheit erkanken, gilt nur ein Leistungsfall.</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Was ist, wenn die Krankheit innerhalb der Wartezeit eintritt, jedoch erst nach dieser festgestellt wurde?</template>
              <template slot="accordion-content">
                <p>Hat der Arzt während der Wartezeit bereits einen Verdacht auf die Diagnose  geäußert, besteht für diese schwere Krankheit kein Versicherungsschutz.</p>
              </template>
            </accordion-item>
          </accordion>
        </div>
        <div class="stepper-content-inner" data-tab="6">
          <h2>Arbeitsunfähigkeit</h2>
          <accordion>
            <accordion-item>
              <template slot="accordion-trigger">Was muss die versicherte Person bei AU machen?</template>
              <template slot="accordion-content">
                <p>Der  Eintritt  einer Arbeitsunfähigkeit, die den leistungsfreien Zeitraum (Karenzzeit) von 42 Tagen übersteigt, ist dem Versicherer unverzüglich anzuzeigen (eClaims).</p>
              </template>
            </accordion-item>
            <accordion-item>
              <template slot="accordion-trigger">Welche Leistungsvoraussetzungen gelten für die Zahlung der Leistung hinsichtlich AU?</template>
              <template slot="accordion-content">
                <p class="margin">Bedingungsgemäß liegt eine versicherte Arbeitsunfähigkeit vor, wenn die versicherte Person infolge einer ärztlich nachgewiesenen Gesundheitsstörung nach Versicherungsbeginn vorübergehend vollständig außerstande ist, ihre bisherige berufliche Tätigkeit auszuüben, sie auch nicht ausübt und keiner anderen Erwerbstätigkeit nachgeht. Welche Unterlagen müssen im Falle der AU eingereicht werden?</p>
                <p class="margin">Zum Nachweis der Arbeitsunfähigkeit muss die versicherte Person uns eine ärztlich attestierte Arbeitsunfähigkeitsbescheinigung und auf unser Verlangen ein ärztliches Zeugnis auf unserem Original-Vordruck einreichen.</p>
                <p class="margin">Die Arbeitsunfähigkeitsbescheinigung muss die zugrunde liegenden Diagnosen und die voraussichtliche Dauer der Arbeitsunfähigkeit enthalten sowie den Anforderungen gemäß der „Arbeitsunfähigkeits-Richtlinie des Gemeinsamen Bundesausschusses“ entsprechen (Gelber Schein).</p>
                <p class="margin">Bei Arbeitsunfähigkeit aufgrund einer psychischen Erkrankung muss diese nach den ersten drei Krankheitsmonaten von einem niedergelassenen Facharzt für Psychiatrie und Psychotherapie, Facharzt für Psychosomatische Medizin und Psychotherapie oder Facharzt für Kinder- und Jugendpsychiatrie und Psychotherapie bescheinigt werden.</p>
              </template>
            </accordion-item>
            <h2 class="mt-md" >Psychische Erkrankungen</h2>
              <accordion-item>
                <template slot="accordion-trigger">Was fällt unter psychische Erkrankung?</template>
                <template slot="accordion-content">
                  <p>Psychische Erkrankungen werden in der aktuell gültigen Fassung der <a target="_blank" href="https://de.wikipedia.org/wiki/Internationale_statistische_Klassifikation_der_Krankheiten_und_verwandter_Gesundheitsprobleme">Internationalen Klassifikation der Krankheiten</a> (ICD-10) unter psychische und Verhaltensstörungen im Kapitel V aufgeführt und haben in der Regel eine F-Diagnose. </p>
                </template>
              </accordion-item>
              <accordion-item>
                <template slot="accordion-trigger">Sind Gesundheitsfragen notwendig?</template>
                <template slot="accordion-content">
                  <p>Bei Vertragsabschluss nein, aber die Versicherung behält sich das Recht vor, im Leistungsfall Informationen vom Arzt anzufordern.</p>
                </template>
              </accordion-item>
              <accordion-item>
                <template slot="accordion-trigger">Was ist, wenn die psychische Erkrankung bei Vertragsabschluss bereits bekannt ist?</template>
                <template slot="accordion-content">
                  <p>Wenn die versicherte Person aufgrund einer psychischen Erkrankung bereits arbeitsunfähig ist, besteht kein Versicherungsschutz für diese Erkrankung. Eine erneute Erkrankung mit einer Arbeitsunfähigkeit, die länger als 6 Wochen andauert und nach Ablauf der Wartezeit eintritt, wäre jedoch abgesichert. </p>
                </template>
              </accordion-item>
          </accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '../../components/Accordion';
import AccordionItem from "../../components/AccordionItem";

export default {
  name: "FAQ",
  components: {
    Accordion,
    AccordionItem
  },
};
</script>
