import Vue from 'vue';
import App from './App.vue';
import VTooltip from 'v-tooltip';
import router from './router';
import './assets/scss/main.scss';

Vue.config.productionTip = false;
Vue.use(VTooltip);
VTooltip.options.defaultHtml = true;


new Vue({
  router,
  render: h => h(App),
}).$mount('#app');

const genericTabs = function(type) {
  const curr = 'current';
  let tabsNavTabs;
  let tabsNav = document.querySelectorAll('.header-nav');
  let tabsContent = document.querySelectorAll('.header-tab-container');

  if (type === 'stepper') {
    tabsNav = document.querySelectorAll('.stepper-nav');
    tabsContent = document.querySelectorAll('.stepper-content-inner');
  }

  for (let k = 0; k < tabsNav.length; k++) {
    if (type === 'stepper') {
      tabsNavTabs = tabsNav[k].querySelectorAll('li');
    } else {
      tabsNavTabs = tabsNav[k].querySelectorAll('.tab');
    }

    if (tabsNavTabs) {
      tabsNavTabs.forEach(function(tabsNavTab) {
        let tabId = tabsNavTab.dataset.tab;
        let tabContainer = document.querySelector('.header-tab-container[data-tabid="' + tabId + '"]');
        if (type === 'stepper') {
          tabContainer = document.querySelector('.stepper-content-inner[data-tab="' + tabId + '"]');
        }

        tabsNavTab.addEventListener('click', function () {
          for (let i = 0; i < tabsNavTabs.length; i++) {
            tabsNavTabs[i].classList.remove(curr);
          }

          let listElems =  document.querySelectorAll('.stepper-nav-list li');
          for (let l = 0; l < listElems.length; l++) {
            listElems[l].classList.remove(curr);
          }

          for (let j = 0; j < tabsContent.length; j++) {
            tabsContent[j].classList.remove(curr);
          }

          this.classList.add(curr);
          if (tabContainer) {
            tabContainer.classList.add(curr);
            if (type !== 'stepper') {
              var headerTabStepperContainerInner = tabContainer.querySelectorAll('.stepper-content-inner');
              if (headerTabStepperContainerInner) {
                for (let k = 0; k < headerTabStepperContainerInner.length; k++) {
                  headerTabStepperContainerInner[k].classList.remove(curr);
                }
                if (headerTabStepperContainerInner[0]) {
                  headerTabStepperContainerInner[0].classList.add(curr);
                  if (headerTabStepperContainerInner[0].dataset.tab == 1) {
                    listElems[0].classList.add(curr);
                  }
                }
              }
            }
          }
        });
      });
    }
  }
}

window.addEventListener('load', () => {
  genericTabs();
  genericTabs('stepper');

  var activeNavListElem = document.querySelector('.nav-list-elem.-is-active.active');
  if (activeNavListElem) {
    activeNavListElem.addEventListener('click', function () {
      var stepperContainerFirstChild = document.querySelector('.header-tab-container .stepper-content .stepper-content-inner:first-child');
      if (stepperContainerFirstChild) {
        stepperContainerFirstChild.classList.add('current');
      }
    });
  }
});

Vue.mixin({
  mounted() {
    this.$nextTick(function () {
      genericTabs();
      genericTabs('stepper');
    })
  }
});
