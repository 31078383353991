<template>
  <div class="set-height-100">
    <div class="stepper-container">
      <stepper-nav :no-selection="noSelection"></stepper-nav>
      <div class="stepper-content current">
        <h1>Schadenmeldung</h1>
        <p>Was müssen du und dein Kunde im Schadenfall beachten?</p>
        <div class="stepper-content-inner current" data-tab="0">
          <div class="container">
            <div class="row">
              <div class="col-4">
                <h2>Allgemein</h2>
                <ul>
                  <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich anzuzeigen.</li>
                  <li>Bei Bedarf zur Klärung der Leistungspflicht kann der Versicherer weitere Auskünfte einholen.</li>
                </ul>
              </div>
              <div class="col-8 pr-md">
                <e-claims gap="4"></e-claims>
              </div>
            </div>
          </div>
        </div>
        <div class="stepper-content-inner" data-tab="1">
          <h2><span v-tooltip="toolTips.elternzeit">Elternzeit</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich (innerhalb von 14 Tagen) anzuzeigen.</li>
            <li>Während der Leistungsdauer ist das Bestehen der Elternzeit nachzuweisen, jegliche Änderungen in Bezug auf den Zeitraum der Elternzeit sind mitzuteilen.</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Benachrichtigung über den Bezug des Elterngeldes</li>
            <li>Eine Kopie der Geburtsurkunde oder des Adoptionsnachweises</li>
          </ul>
        </div>
        <div class="stepper-content-inner" data-tab="2">
          <h2><span v-tooltip="toolTips.kurzzeitpflege">Kurzzeitpflege</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich (innerhalb von 14 Tagen) anzuzeigen.</li>
            <li>Während der Leistungsdauer ist das Bestehen der Pflegezeit nachzuweisen, jegliche Änderungen in Bezug auf den Zeitraum der Pflegezeit sind mitzuteilen.</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Bestätigung des Arbeitgebers mit Beginn- und Enddatum der Pflegezeit</li>
          </ul>
        </div>
        <div class="stepper-content-inner" data-tab="3">
          <h2><span v-tooltip="toolTips.kurzarbeit">Kurzarbeit</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Das Eintreten von konjunktureller Kurzarbeit ist der Versicherung unverzüglich (innerhalb von 14 Tagen) anzuzeigen.</li>
            <li>Während der Leistungsdauer ist das Bestehen der Kurzarbeit nachzuweisen und jegliche Änderungen bzgl. der Kurzarbeit sind mitzuteilen.</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Bestätigung des Arbeitgebers mit Start- und Enddatum der Kurzarbeit und Grund der Kurzarbeit</li>
            <li>Bestätigung des Jobcenters über die Anerkennung der Kurzarbeit nach § 95 SGB III mit Start- und Enddatum</li>
          </ul>
        </div>
        <div class="stepper-content-inner" data-tab="4">
          <h2><span v-tooltip="toolTips.arbeitslosigkeit">Arbeitslosigkeit</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Beginn und Ende der unverschuldeten Arbeitslosigkeit unverzüglich in Textform (binnen 14 Tagen)</li>
            <li>Eine vom letzten Arbeitgeber für die Agentur für Arbeit ausgefüllte Arbeitsbescheinigungskopie</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Das mit Kündigungsgrund versehene Kündigungsschreiben oder eine Bescheinigung des Arbeitgebers über den Kündigungsgrund</li>
            <li>Eine Bescheinigung der Agentur für Arbeit, aus der sich ergibt, seit wann die versicherte Person ununterbrochen als arbeitslos und als arbeitsuchend gemeldet ist</li>
          </ul>
        </div>
        <div class="stepper-content-inner" data-tab="5">
          <h2><span v-tooltip="toolTips.schwereKrankheiten">Schwere Krankheiten</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich (innerhalb von 14 Tagen) anzuzeigen.</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Zum Nachweis der schweren Krankheit muss eine ärztlich attestierte Bescheinigung mit Diagnose von einem Facharzt ausgestellt und auf Verlangen der Versicherung ein ärztliches Zeugnis eingereicht werden.</li>
          </ul>
        </div>
        <div class="stepper-content-inner" data-tab="6">
          <h2><span v-tooltip="toolTips.arbeitsunfaehigkeit">Arbeitsunfähigkeit (Inklusive psychischer Erkrankung)</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Das Eintreten des Versicherungsfalls ist der Versicherung unverzüglich (binnen 14 Tagen) anzuzeigen.</li>
            <li>Die Wiederherstellung der Arbeitsfähigkeit ist dem Versicherer ebenfalls unverzüglich anzuzeigen.</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Ärztlich attestierte Arbeitsunfähigkeitsbescheinigung (AU), auf der die Diagnose und die voraussichtliche Dauer der Arbeitsunfähigkeit enthalten sind</li>
            <li>Die AU muss den Anforderungen der „Arbeitsunfähigkeits-Richtlinie des Gemeinsamen Bundesausschusses“ entsprechen. Mehr Informationen hierzu unter <a target="_blank" href="https://www.g-ba.de/richtlinien/2/">www.g-ba.de/richtlinien/2/</a></li>
          </ul>
        </div>
        <div class="stepper-content-inner" data-tab="7">
          <h2><span v-tooltip="toolTips.unfalltod">Unfalltod</span></h2>
          <p class="mb-xs"><strong>Meldung beim Versicherer:</strong></p>
          <ul>
            <li>Der Tod der versicherten Person ist dem Versicherer unverzüglich (innerhalb von 14 Tagen) anzuzeigen.</li>
          </ul>
          <p class="pt-xxs pt-sm--lg mb-xs"><strong>Erforderliche Unterlagen:</strong></p>
          <ul>
            <li>Eine Alter und Geburtsort enthaltende amtliche Sterbeurkunde</li>
            <li>Ein amtliches Zeugnis über die Ursache, die zum Tode der versicherten Person geführt hat</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StepperNav from "../../components/StepperNav";
import eClaims from "../../components/eClaims";
export default {
  name: "Schadensmeldung",
  components: {
    StepperNav,
    eClaims
  },
  data() {
    return {
      noSelection: true,
      toolTips: {
        elternzeit:
            '<h3>Elternzeit</h3>' +
            '<ul>' +
            '<li>Das Elterngeld soll den Einkommenseinbruch nach der Geburt eines Kindes reduzieren und wird von den staatlichen Elterngeldstellen ausgezahlt.</li>' +
            '<li>Elternzeit kann jedes Elternteil in Anspruch nehmen, das in einem Arbeitsverhältnis steht (Vollzeit, Teilzeit, Ausbildung, geringfügige und/oder befristete Beschäftigung).</li>' +
            '<li>Das Basiselterngeld beträgt i. d. R. zwischen 65 % und 67 % des Nettoeinkommens, aber mind. 300 € bzw. max. 1.800 € monatlich.</li>' +
            '</ul>',
        deckungen:
            '<h3>Max. Zahlungen über alle Deckungen</h3>' +
            '<p>Der Kunde erhält während der gesamten Vertragslaufzeit max. 36 Zahlungen – über alle Deckungen und Schadenfälle hinweg.</p>',
        wartezeit:
            '<h3>Wartezeit</h3>' +
            '<p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz und damit kein Leistungsanspruch besteht. Erst wenn nach der Wartezeit ein Kind geboren oder adoptiert wird, hat der Kunde einen Leistungsanspruch.</p>',
        karenzzeit:
            '<h3>Karenzzeit</h3>' +
            '<p>Die Karenzzeit ist die leistungsfreie Zeit nach Eintritt des Versicherungsfalles, für die kein Leistungsanspruch geltend gemacht werden kann.</p>',
        kurzzeitpflege:
            '<h3>Kurzzeitpflege</h3>' +
            '<ul>' +
            '<li>Familienpflegezeit / Kurzzeitpflege bezeichnet die teilweise Freistellung für die Pflege eines pflegebedürftigen nahen Angehörigen in häuslicher Umgebung über einen Zeitraum von bis zu 24 Monaten bei einer wöchentlichen Mindestarbeitszeit von 15 Stunden.</li>' +
            '<li>Beschäftigte in Unternehmen mit 25 oder mehr Angestellten haben einen Anspruch auf Familienpflegezeit.</li>' +
            '</ul>',
        verwandte:
            '<h3>Nahe Angehörige</h3>' +
            '<ul>' +
            '<li>Als nahe Angehörige gelten: Großeltern und Eltern, Schwiegereltern, Ehegatten, Lebenspartner oder Partner einer eheähnlichen Gemeinschaft, Stiefeltern, Lebenspartner der Geschwister, Geschwister der Lebenspartner sowie für Partner in lebenspartnerschaftsähnlichen Gemeinschaften, Geschwister, Kinder, Adoptivkinder, sowie Kinder oder Adoptivkinder des Ehegatten oder Lebenspartners, sowie Schwieger- und Enkelkinder.</li>' +
            '<li>Pflegekinder gelten nicht als nahe Angehörige.</li>' +
            '</ul>',
        kurzarbeit:
            '<h3>Konjunkturelle Kurzarbeit</h3>' +
            '<ul>' +
            '<li>Konjunkturelle Kurzarbeit ist für ein Unternehmen das Mittel der Wahl, wenn Mitarbeiter aufgrund der wirtschaftlichen Situation unterbeschäftigt sind, aber nicht entlassen werden sollen.</li>' +
            '<li>Sie kann bei Auftragsausfällen durch entsprechende Vereinbarungen zur Reduzierung der Arbeitszeit im Betrieb sehr kurzfristig eingeführt werden.</li>' +
            '</ul>',
        arbeitslosigkeit:
            '<h3>Arbeitslosigkeit</h3>' +
            '<p>Arbeitslose sind Personen, die […] nicht in einem Beschäftigungsverhältnis stehen, eine versicherungspflichtige Beschäftigung suchen und dabei den Vermittlungsbemühungen der Agentur für Arbeit zur Verfügung stehen und sich bei der Agentur für Arbeit arbeitslos gemeldet haben (§ 16 Absatz 1 SGB III).</p>',
        schwereKrankheiten:
            '<h3>Schwere Krankheiten</h3>' +
            '<ul>' +
            '<li>Der Versicherer ordnet folgende Erkrankungen als schwere Krankheiten ein: invasiver Krebs, Herzinfarkt, Schlaganfall, Nierenversagen, Hauptorgantransplantation, Bypassoperation der Koronararterien.</li>' +
            '<li>Mehr Infos in den AVBs unter „Besondere Bedingungen für den Leistungsbaustein Santander Protect bei schweren Krankheiten“</li>' +
            '</ul>',
        grobeFahrlaessigkeit:
            '<h3>Grobe Fahrlässigkeit</h3>' +
            '<p>Es wird grob fahrlässig gehandelt, wenn ein Schaden durch einfache und naheliegende Verhaltensweisen hätte verhindert werden können und diese außer Acht gelassen wurden.</p>',
        arbeitsunfaehigkeit:
            '<h3>Arbeitsunfähigkeit</h3>' +
            '<p>Ein Arbeitnehmer ist arbeitsunfähig, wenn er aufgrund einer Krankheit seine zuletzt ausgeübte Tätigkeit nicht mehr ausführen kann oder die Ausführung der jeweiligen Tätigkeit mit der Gefahr der Verschlimmerung der Krankheit einhergeht.</p>',
        unfalltod:
            '<h3>Unfalltod</h3>' +
            '<p>Ein Unfall liegt vor, wenn die versicherte Person durch ein plötzlich von außen auf den Körper wirkendes Ereignis (Unfallereignis) unfreiwillig eine Gesundheitsschädigung mit Todesfolge erleidet.</p>',

      }
    }
  }
};
</script>
