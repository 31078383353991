var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"footer-nav-wrapper"},[_c('div',{staticClass:"grid-split -split-3"},[_c('div',[_c('ul',{staticClass:"nav-list",class:{
        'active':
          _vm.isRoute('Produkte')
        }},[_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'Produkte' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("Produkte")])])])],1)]),_c('div',[_c('ul',{staticClass:"nav-list",class:{
        'active':
           _vm.isRoute('Beratungshilfe') ||
           _vm.isRoute('VorsorgeRechner')
        }},[_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'Beratungshilfe' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("Beratungshilfe")])])]),_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'VorsorgeRechner' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("Einkommenslücken-Rechner")])])])],1)]),_c('div',[_c('ul',{staticClass:"nav-list",class:{
        'active':
           _vm.isRoute('Schadensmeldung') ||
           _vm.isRoute('FAQ') ||
           _vm.isRoute('AVB') ||
           _vm.isRoute('Kontakt')
        }},[_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'Schadensmeldung' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("Schadenmeldung")])])]),_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'FAQ' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("FAQ")])])]),_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'AVB' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("AVB")])])]),_c('router-link',{staticClass:"nav-list-elem",attrs:{"tag":"li","active-class":"active","exact":"","to":{ name: 'Kontakt' }}},[_c('a',{staticClass:"tab-link",on:{"click":_vm.reloadPage}},[_c('span',[_vm._v("Kontakt")])])])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }