<template>
  <div class="header-tab-container current padded">
    <h2>Übersicht Ihrer möglichen Einkommenslücken</h2>
    <div class="row mt-xs">
      <div class="col-4"></div>
      <div class="col-8 pl-lg">
        <p class="font-size-md" style="max-width: inherit">
          <strong>Mit diesen gesetzlichen Leistungen können Sie rechnen, wenn Ihr Arbeitseinkommen wegfällt:*</strong>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <div class="input-wrapper pb-xxs pb-mb--lg">
          <label>Kundendaten:</label>
        </div>
        <div class="input-wrapper">
          <label for="name">Name:</label>
          <input type="text" id="name" name="name" placeholder="Max Mustermann" />
        </div>
        <div class="input-wrapper number">
          <label for="name">Monatl. Nettogehalt:</label>
          <input type="number"
                 step="any"
                 min="0"
                 @input="updateNettoValue"
                 :value="netto"
                 placeholder="0.00"
          />
        </div>
      </div>
      <div class="col-8 pl-md">
        <div class="column-chart-wrapper -large">
          <column-chart-item :number="netto" :fix="fix" :multiplicator="1" icon="netto" subline="Nettoeinkommen" />
          <column-chart-item :number="netto" :fix="fix" :multiplicator=".8" icon="ka" subline="Kurzarbeit" />
          <column-chart-item :number="netto" :fix="fix" :multiplicator=".8" icon="kg" subline="Arbeitsunfähigkeit" />
          <column-chart-item :number="netto" :fix="fix" :multiplicator=".6" icon="alg" subline="Arbeitslosengeld" />
          <column-chart-item :number="netto" :fix="fix" :multiplicator=".65" calculation-method='parentalLeave' icon="eg" subline="Elternzeit" />
          <column-chart-item :number="netto" :fix="fix" :multiplicator=".6" calculation-method="statutoryPensionInsurance" icon="grv" subline="Rentenversicherung" />
        </div>
      </div>
    </div>
    <p class="c-gray-darkest" style="font-size: 1rem; max-width: 110rem; padding-top: 1.5rem">* Die hier angegebenen Werte sind unverbindliche Schätzungen, die auf allgemeinen Angaben und Annahmen basieren. Ihren persönlichen Leistungsanspruch können Sie bei den zuständigen Leistungsträgern (Krankenkasse, gesetzliche Rentenversicherung etc.) in Erfahrung bringen.
      Die angegebenen Daten in „Name“ und „Monatl. Nettogehalt“ werden nicht gespeichert, sondern dienen lediglich der Individualisierung der Darstellung. Gesetzliche Leistungen können gedeckelt sein. Beispielsweise liegt das Elterngeld aktuell bei max. 1.800 €, die gesetzliche Rente bei rund 2.600 €.<br>
      Hinweis: Mögliche Kreditausfallrisiken sind über die RSV zu decken und dürfen nicht Bestandteil der Protect sein.
    </p>
  </div>
</template>

<script>
import ColumnChartItem from "../../components/columnChartItem";

export default {
  name: "VorsorgeRechner",
  components: {
    ColumnChartItem
  },
  data() {
    return {
      netto: null,
      fix: null,
      max: 5
    }
  },
  methods: {
    updateNettoValue(event) {
      const value = event.target.value
      if (String(value).length <= this.max) {
        this.netto = value
      }
      this.$forceUpdate()
    },
    updateFixValue(event) {
      const value = event.target.value
      if (String(value).length <= this.max) {
        this.fix = value
      }
      this.$forceUpdate()
    }
  }
};
</script>
