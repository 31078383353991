<template>
  <div class="set-height-100">
    <header-tabs :page-tabs="pageTabs"></header-tabs>
    <div class="header-tab-container padded current" data-tabid="1">
      <div class="stepper-container current">
        <div class="stepper-content set-width-100">
          <h2 class="pt-sm">Schützen Sie sich vor Einkommensausfällen*</h2>
          <div class="column-chart-wrapper -large pt-xxs pt-sm--lg">
            <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen" />
            <column-chart-item number="2000" :multiplicator=".8" icon="ka" subline="Kurzarbeit" />
            <column-chart-item number="2000" :multiplicator=".8" icon="kg" subline="Arbeitsunfähigkeit" />
            <column-chart-item number="2000" :multiplicator=".6" icon="alg" subline="Arbeitslosengeld" />
            <column-chart-item number="2000" :multiplicator=".65" calculation-method="parentalLeave" icon="eg" subline="Elternzeit" />
            <column-chart-item number="2000" :multiplicator=".6" calculation-method="statutoryPensionInsurance" icon="grv" subline="Rentenversicherung" />
          </div>
          <p class="font-size-small pt-xs">* Alle Werte sind grobe Schätzwerte. Gesetzliche Leistungen können gedeckelt sein.<br> Beispielsweise liegt das Elterngeld aktuell bei max. 1.800 €, die gesetzliche Rente bei rund 2.600 €.<br>
          Hinweis: Mögliche Kreditausfallrisiken sind über die RSV zu decken und dürfen nicht Bestandteil der Protect sein.</p>
        </div>
      </div>
    </div>
    <div class="header-tab-container" data-tabid="2">
      <div class="stepper-container">
        <stepper-nav></stepper-nav>
        <div class="stepper-content pt-xs">
          <div class="stepper-content-inner current" data-tab="1">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.elternzeit">Elternzeit</span></h2>
                <div class="font-size-md">
                  <p><strong>Nehmen Sie sich Zeit für Ihre Familie –<br>ohne Sorgen um Ihr Einkommen.</strong></p>
                  <ul>
                    <li>Einkommenslücke schließen</li>
                    <li>Bis zu 6 Monate Leistungen erhalten</li>
                    <li>Adoptionen sind ebenfalls versichert.</li>
                  </ul>
                </div>
              </div>
              <div class="col-5">
                <div class="column-chart-wrapper pt-sm">
                  <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen"></column-chart-item>
                  <column-chart-item number="2000" :multiplicator=".65" icon="eg" subline="Bsp. Einkommensausfall während der Elternzeit"></column-chart-item>
                </div>
              </div>
            </div>
            <p class="font-size-small">Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
          <div class="stepper-content-inner" data-tab="2">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.kurzzeitpflege">Kurzzeitpflege</span></h2>
                <div class="font-size-md">
                  <p><strong>Sie sorgen für den Menschen, der Ihnen wichtig ist.<br>Wir sorgen für Ihr Einkommen.</strong></p>
                  <ul>
                    <li>Einkommenslücke schließen</li>
                    <li>Bis zu 6 Monate Leistungen erhalten</li>
                    <li>Pflege direkter und naher Verwandter mitversichert</li>
                  </ul>
                </div>
              </div>
              <div class="col-5">
                <div class="column-chart-wrapper pt-sm">
                  <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen"></column-chart-item>
                  <column-chart-item number="2000" :multiplicator=".8" icon="kg" subline="Bsp. Einkommensausfall während der Kurzzeitpflege"></column-chart-item>
                </div>
              </div>
            </div>
            <p class="font-size-small">Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
          <div class="stepper-content-inner" data-tab="3">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.kurzarbeit">Kurzarbeit</span></h2>
                <div class="font-size-md">
                  <p><strong>Auch in schwierigen Zeiten bleibt Ihr Einkommen stabil.</strong></p>
                  <ul>
                    <li>Einkommenslücke schließen</li>
                    <li>Bis zu 12 Monate Leistungen erhalten</li>
                    <li>Auch bei befristeten Arbeitsverträgen</li>
                  </ul>
                </div>
              </div>
              <div class="col-5">
                <div class="column-chart-wrapper pt-sm">
                  <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen"></column-chart-item>
                  <column-chart-item number="2000" :multiplicator=".8" icon="ka" subline="Bsp. Einkommensausfall während der Kurzarbeit"></column-chart-item>
                </div>
              </div>
            </div>
            <p class="font-size-small">Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
          <div class="stepper-content-inner" data-tab="4">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.arbeitslosigkeit">Arbeitslosigkeit</span></h2>
                <div class="font-size-md">
                  <p>
                    <strong>Konzentrieren Sie sich auf Ihre Arbeitssuche –<br>
                    ohne finanzielle Sorgen.</strong>
                  </p>
                  <ul>
                    <li>Planbares Einkommen trotz Arbeitslosigkeit</li>
                    <li>Bis zu 12 Monate Leistungen erhalten</li>
                    <li>Arbeitnehmer und Angestellte im öffentlichen Dienst mitversichert</li>
                  </ul>
                </div>
              </div>
              <div class="col-5">
                <div class="column-chart-wrapper pt-sm">
                  <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen"></column-chart-item>
                  <column-chart-item number="2000" :multiplicator=".6" icon="alg" subline="Bsp. Einkommensausfall während der Arbeitslosigkeit"></column-chart-item>
                </div>
              </div>
            </div>
            <p class="font-size-small">Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
          <div class="stepper-content-inner" data-tab="5">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.schwereKrankheiten">Schwere Krankheiten</span></h2>
                <div class="font-size-md">
                  <p><strong>Konzentrieren Sie sich auf Ihre Genesung – <br>ohne Geldsorgen.</strong></p>
                  <ul>
                    <li>Für Beamte, Selbstständige und Rentner</li>
                    <li>Bis zu 12 Monate Leistungen erhalten</li>
                    <li>Ohne Gesundheitsfragen</li>
                  </ul>
                </div>
              </div>
              <div class="col-5">
                <div class="column-chart-wrapper pt-sm">
                  <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen"></column-chart-item>
                  <column-chart-item number="2000" :multiplicator=".6" icon="kg" subline="Bsp. Einkommensausfall während schwerer Krankheiten"></column-chart-item>
                </div>
              </div>
            </div>
            <p class="font-size-small">Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
          <div class="stepper-content-inner" data-tab="6">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.arbeitsunfaehigkeit">Arbeitsunfähigkeit<br>(Inklusive psychischer Erkrankung)</span></h2>
                <div class="font-size-md">
                  <p><strong>Konzentrieren Sie sich auf Ihre Genesung – <br>ohne Geldsorgen.</strong></p>
                  <ul>
                    <li>Psychische Erkrankungen mitversichert</li>
                    <li>Bis zu 12 Monate Leistungen erhalten</li>
                    <li>Ohne Gesundheitsfragen</li>
                  </ul>
                </div>
              </div>
              <div class="col-5">
                <div class="column-chart-wrapper pt-sm">
                  <column-chart-item number="2000" :multiplicator="1" icon="netto" subline="Nettoeinkommen"></column-chart-item>
                  <column-chart-item number="2000" :multiplicator=".8" icon="kg" subline="Bsp. Einkommensausfall während der Arbeitsunfähigkeit"></column-chart-item>
                </div>
              </div>
            </div>
            <p class="font-size-small">Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
          <div class="stepper-content-inner" data-tab="7">
            <div class="row">
              <div class="col-6">
                <h2 class="pb-xxs"><span v-tooltip="toolTips.unfalltod">Unfalltod*</span></h2>
                <div class="font-size-md">
                  <p><strong>Sorgen Sie vor – für Ihre Liebsten.</strong></p>
                  <ul>
                    <li>Hinterbliebene absichern</li>
                    <li>Einmalige Versicherungsleistung</li>
                    <li>Ohne Wartezeit</li>
                  </ul>
                </div>
              </div>
              <div class="col-5" style="padding-top: 4rem;">
                <img style="max-width: 25rem;" src="@/assets/img/unfalltod.svg" />
              </div>
            </div>
            <p class="font-size-small">* maximale Leistung 50.000 Euro<br>Hinweis: Mögliche Kreditausfallrisiken sind über die RSV<br>zu decken und dürfen nicht Bestandteil der Protect sein.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTabs from "../../components/HeaderTabs";
import StepperNav from "../../components/StepperNav";
import ColumnChartItem from "../../components/columnChartItem";

export default {
  name: "Beratungshilfe",
  components: {
    StepperNav,
    HeaderTabs,
    ColumnChartItem
  },
  data() {
    return {
      toolTips: {
        elternzeit:
            '<h3>Elternzeit</h3>' +
            '<ul>' +
            '<li>Das Elterngeld soll den Einkommenseinbruch nach der Geburt eines Kindes reduzieren und wird von den staatlichen Elterngeldstellen ausgezahlt.</li>' +
            '<li>Elternzeit kann jedes Elternteil in Anspruch nehmen, das in einem Arbeitsverhältnis steht (Vollzeit, Teilzeit, Ausbildung, geringfügige und/oder befristete Beschäftigung).</li>' +
            '<li>Das Basiselterngeld beträgt i. d. R. zwischen 65 % und 67 % des Nettoeinkommens, aber mind. 300 € bzw. max. 1.800 € monatlich.</li>' +
            '</ul>',
        deckungen:
            '<h3>Max. Zahlungen über alle Deckungen</h3>' +
            '<p>Der Kunde erhält während der gesamten Vertragslaufzeit max. 36 Zahlungen – über alle Deckungen und Schadenfälle hinweg.</p>',
        wartezeit:
            '<h3>Wartezeit</h3>' +
            '<p>Die Wartezeit ist der Zeitraum, für den kein Versicherungsschutz und damit kein Leistungsanspruch besteht. Erst wenn nach der Wartezeit ein Kind geboren oder adoptiert wird, hat der Kunde einen Leistungsanspruch.</p>',
        karenzzeit:
            '<h3>Karenzzeit</h3>' +
            '<p>Die Karenzzeit ist die leistungsfreie Zeit nach Eintritt des Versicherungsfalles, für die kein Leistungsanspruch geltend gemacht werden kann.</p>',
        kurzzeitpflege:
            '<h3>Kurzzeitpflege</h3>' +
            '<ul>' +
            '<li>Familienpflegezeit / Kurzzeitpflege bezeichnet die teilweise Freistellung für die Pflege eines pflegebedürftigen nahen Angehörigen in häuslicher Umgebung über einen Zeitraum von bis zu 24 Monaten bei einer wöchentlichen Mindestarbeitszeit von 15 Stunden.</li>' +
            '<li>Beschäftigte in Unternehmen mit 25 oder mehr Angestellten haben einen Anspruch auf Familienpflegezeit.</li>' +
            '</ul>',
        verwandte:
            '<h3>Nahe Angehörige</h3>' +
            '<ul>' +
            '<li>Als nahe Angehörige gelten: Großeltern und Eltern, Schwiegereltern, Ehegatten, Lebenspartner oder Partner einer eheähnlichen Gemeinschaft, Stiefeltern, Lebenspartner der Geschwister, Geschwister der Lebenspartner sowie für Partner in lebenspartnerschaftsähnlichen Gemeinschaften, Geschwister, Kinder, Adoptivkinder, sowie Kinder oder Adoptivkinder des Ehegatten oder Lebenspartners, sowie Schwieger- und Enkelkinder.</li>' +
            '<li>Pflegekinder gelten nicht als nahe Angehörige.</li>' +
            '</ul>',
        kurzarbeit:
            '<h3>Konjunkturelle Kurzarbeit</h3>' +
            '<ul>' +
            '<li>Konjunkturelle Kurzarbeit ist für ein Unternehmen das Mittel der Wahl, wenn Mitarbeiter aufgrund der wirtschaftlichen Situation unterbeschäftigt sind, aber nicht entlassen werden sollen.</li>' +
            '<li>Sie kann bei Auftragsausfällen durch entsprechende Vereinbarungen zur Reduzierung der Arbeitszeit im Betrieb sehr kurzfristig eingeführt werden.</li>' +
            '</ul>',
        arbeitslosigkeit:
            '<h3>Arbeitslosigkeit</h3>' +
            '<p>Arbeitslose sind Personen, die […] nicht in einem Beschäftigungsverhältnis stehen, eine versicherungspflichtige Beschäftigung suchen und dabei den Vermittlungsbemühungen der Agentur für Arbeit zur Verfügung stehen und sich bei der Agentur für Arbeit arbeitslos gemeldet haben (§ 16 Absatz 1 SGB III).</p>',
        schwereKrankheiten:
            '<h3>Schwere Krankheiten</h3>' +
            '<ul>' +
            '<li>Der Versicherer ordnet folgende Erkrankungen als schwere Krankheiten ein: invasiver Krebs, Herzinfarkt, Schlaganfall, Nierenversagen, Hauptorgantransplantation, Bypassoperation der Koronararterien.</li>' +
            '<li>Mehr Infos in den AVBs unter „Besondere Bedingungen für den Leistungsbaustein Santander Protect bei schweren Krankheiten“</li>' +
            '</ul>',
        grobeFahrlaessigkeit:
            '<h3>Grobe Fahrlässigkeit</h3>' +
            '<p>Es wird grob fahrlässig gehandelt, wenn ein Schaden durch einfache und naheliegende Verhaltensweisen hätte verhindert werden können und diese außer Acht gelassen wurden.</p>',
        arbeitsunfaehigkeit:
            '<h3>Arbeitsunfähigkeit</h3>' +
            '<p>Ein Arbeitnehmer ist arbeitsunfähig, wenn er aufgrund einer Krankheit seine zuletzt ausgeübte Tätigkeit nicht mehr ausführen kann oder die Ausführung der jeweiligen Tätigkeit mit der Gefahr der Verschlimmerung der Krankheit einhergeht.</p>',
        unfalltod:
            '<h3>Unfalltod</h3>' +
            '<p>Ein Unfall liegt vor, wenn die versicherte Person durch ein plötzlich von außen auf den Körper wirkendes Ereignis (Unfallereignis) unfreiwillig eine Gesundheitsschädigung mit Todesfolge erleidet.</p>',

      },
      pageTabs: {
        1: {
          'name': 'Bedarf'
        },
        2: {
          'name': 'Lösungen'
        }
      }
    }
  }
};
</script>
